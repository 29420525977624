import React from "react";
import {
  SimpleTextField,
  CustomFormControl,
  SimplePhoneField,
} from "components/formField";
import Asynchronous from "components/autoComplete";
import { DeliveryHead } from "assets/icons";
import { Head, SubmitButton, Toast } from "components";

const styles = {
  text: {
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "22px",
  },
  input: {
    width: "100%",
    marginBottom: 15,
  },
};
const DeliveryField = ({
  data: { name, phone, 
    // type, 
    mode_of_entry, number_plate, 
    purpose_of_visit, employer,
    fin_number
   },
  handleCustomChange,
  validation,
  handleInputChange,
  handleChange,
  // Deliverytypes,
  blockObj,
  blockList,
  validationErrors,
  loading,
  formSubmit,
  handleChangeNric
}) => {
  return (
    <React.Fragment>
      <Head subHeading={"Delivery Self-Checkin Service"} />
      <div className="main-root">
        <div className="main-container">
          <div className="head-content-title">
            <p style={{ margin: "15px 0px 5px" }}>Hello there,</p>
            <p style={{ margin: "5px 0px 15px" }}>Kindly fill your details</p>
          </div>
          <DeliveryHead />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formSubmit();
            }}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="form-container" style={{ width: "100%" }}>
            <SimpleTextField
                id="name"
                name="name"
                label="name"
                value={name}
                onChange={handleInputChange}
                // error={validation.phone}
                helperText=" "
              />
              <SimplePhoneField
                id="phone"
                name="phone"
                label="Phone"
                value={phone}
                onChange={handleCustomChange}
                inputProps={{
                  maxLength: 8,
                  type: "tel",
                }}
                error={validation.phone}
                required
                helperText=" "
              />
              <SimpleTextField
                label="NRIC/FIN (Last 4 digits)"
                name="fin_number"
                id="fin_number"
                required
                value={fin_number}
                onChange={(e) => handleChangeNric(e.target.value)}
                helperText=" "
                autoComplete="off"
              />
               <CustomFormControl
                variant="outlined"
                className={"text-field"}
                style={{ ...styles.input, ...styles.text }}
              >
                <Asynchronous
                  label="Block"
                  name="block"
                  handleChange={(e) => handleChange("block", e.target.value)}
                  dataPromise={blockList}
                  required={blockObj.mandatory}
                  helperText=" "
                />
              </CustomFormControl>
              <SimpleTextField
                id="purpose_of_visit"
                name="purpose_of_visit"
                label="Purpose of visit"
                value={purpose_of_visit}
                onChange={handleInputChange}
                // error={validation.phone}
                helperText=" "
              />
              <SimpleTextField
                id="employer"
                name="employer"
                label="Employer"
                value={employer}
                onChange={handleInputChange}
                // error={validation.phone}
                helperText=" "
              />
              {/* <CustomFormControl
                variant="outlined"
                className={"text-field"}
                style={{ ...styles.input, ...styles.text }}
              >
                <Asynchronous
                  label="Type"
                  name="type"
                  value={type}
                  handleChange={(e) => handleChange("type", e.target.value)}
                  required
                  dataPromise={Deliverytypes.sort().map((x) => ({
                    label: x,
                    value: x,
                  }))}
                  helperText=" "
                />
              </CustomFormControl> */}

             

              <p
                className="custom-text"
                style={{ margin: "", textAlign: "start" }}
              >
                Vehicle Details :
              </p>
              <div className="radio-group">
                <input
                  type="radio"
                  id="car"
                  name="car"
                  onClick={(e) => {
                    handleChange("mode_of_entry", e.target.id);
                  }}
                  checked={mode_of_entry === "car" ? true : false}
                />
                <label className="label" htmlFor="car">
                  Car/M-Bike
                </label>
                <input
                  type="radio"
                  id="escooter"
                  name="selector"
                  onClick={(e) => {
                    handleChange("mode_of_entry", e.target.id);
                  }}
                  checked={mode_of_entry === "escooter" ? true : false}
                />
                <label className="label" htmlFor="escooter" name="escooter">
                  Bicycle
                </label>
                <input
                  type="radio"
                  id="walkin"
                  name="selector"
                  onChange={(e) => {
                    handleChange("mode_of_entry", e.target.id);
                  }}
                  checked={mode_of_entry === "walkin" ? true : false}
                />
                <label className="label" htmlFor="walkin">
                  Walk In
                </label>
              </div>

              {(mode_of_entry === "car" || mode_of_entry === "bike") && (
                <SimpleTextField
                  id="number_plate"
                  name="number_plate"
                  label="Number Plate"
                  value={number_plate}
                  onChange={handleInputChange}
                  required
                />
              )}
            </div>
            {validationErrors && <Toast msg={validationErrors} />}
            <div
              style={{
                textAlign: "center",
                paddingBottom: "8%",
                paddingTop: "4%",
                width: "100%",
              }}
            >
              <SubmitButton text="SUBMIT" loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DeliveryField;
