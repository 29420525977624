import React from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { color } from "../config";
import { makeStyles } from "@material-ui/core/styles";

export const styles = {
  text: {
    fontStyle: "normal",
    fontSize: 18,
    lineHeight: 22,
  },
  input: {
    marginBottom: 15,
    marginRight: 10,
  },
};

const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: "#dedede",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#dedede",
      },
    },
    "& .MuiInputLabel-root": {
      color: color,
      fontFamily: "Montserrat",
      fontSize:"14px",
      fontWeight:"300"
    },
  },
}));

const Asynchronous = ({ dataPromise, handleChange, label, name, required }) => {
  const classes = useStyles();
  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ height: "58.38px" }}
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      onChange={(event, value) => {
        handleChange({
          target: { value: (value && value["value"]) || "", name: name },
        });
      }}
      options={dataPromise}
      loading={true}
      noOptionsText="No Options"
      loadingText="No Options"
      renderInput={(params) => (
        <TextField
          {...params}
          classes={classes}
          label={label}
          variant="outlined"
          style={{ ...styles.input }}
          InputLabelProps={{
            style: {
              color,
            },
          }}
          required={required}
          className="text-field"
        />
      )}
    />
  );
};
export default Asynchronous;
