import React, { Component } from "react";
import { verifyStaff, verifyOtp, createInvite } from "../../api/webInvite";
import InviteOTP from "./inviteOtp";
import InvitePage from "./invitePage";
import StaffForm from "./residentForm";
import SuccessPage from "./successPage";

class Invitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        phone: "",
        otp_token: "",
      },
      validation: {
        phone: false,
      },
      inviteData: {
        name: "",
        phone: "",
        fin_number: "",
        country_code: { label: "SG", value: "+65" },
        employer: "",
        visitor_type: "guest",
        visiting_time: null,
        remarks: "",
      },
      inviteValidation: {
        phone: false,
      },
      nricPrier: "XXXXX",
      auth_key: null,
      otpPage: false,
      invitePage: false,
      successPage: false,
      disableBtn: false,
      employee_id: null,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    this.setState({ data: { ...data, [name]: 
      
      
      value }, validationErrors: null });
  };

  handleChangeNric = (value) => {
    const { inviteData, nricPrier } = this.state;
    let { fin_number } = inviteData;

    let fin = fin_number
      ? value.slice(0, 9)
      : `${nricPrier}${value.slice(0, 4)}`;
    this.setState({ inviteData: { ...inviteData, fin_number: fin } });
  };

  handleCustomChange = (name, value, valid) => {
    const { data, validation } = this.state;
    this.setState({
      data: { ...data, [name]: value },
      validation: { ...validation, [name]: valid },
    });
  };
  handleInviteCustomChange = (name, value, valid) => {
    const { inviteData, inviteValidation } = this.state;
    this.setState({
      inviteData: { ...inviteData, [name]: value },
      inviteValidation: { ...inviteValidation, [name]: valid },
    });
  };
  handleVisitingTime = (value) => {
    const { inviteData } = this.state;
    this.setState({ inviteData: { ...inviteData, visiting_time: value } });
  };
  handlePurposeChange = (e) => {
    const { name, value } = e.target;
    const { inviteData } = this.state;
    this.setState({
      inviteData: { ...inviteData, [name]: value, sub_visitor_type: "" },
    });
  };
  handleInviteeChange = (e) => {
    const { name, value } = e.target;
    const { inviteData } = this.state;
    this.setState({ inviteData: { ...inviteData, [name]: value } });
  };

  otpSubmit = () => {
    const { phone } = this.state.data;

    this.setState({
      disableBtn: true,
    });
    verifyStaff({ phone })
      .then((data) => {
        this.setState({
          validationErrors: { success: [200, { message: data.message }] },
        });
        let display = data.auth_key ? "" : "otpPage";
        setTimeout(() => {
          this.setState({
            auth_key: data.auth_key,
            [display]: true,
            disableBtn: false,
            validationErrors: null,
          });
        }, 3000);
      })
      .catch((err) => {
        let msg = err[0] === 404 && "This is not a Registered Number";
        this.setState({
          validationErrors: { err, msg },
          disableBtn: false,
        });
      });
  };

  validateOtp = () => {
    const { otp_token, phone } = this.state.data;
    let requestParams = {
      otp_token,
      phone,
    };
    this.setState({ disableBtn: true });
    verifyOtp(requestParams)
      .then(({ data, message }) => {
        if (data.auth_token) {
          this.setState({
            validationErrors: { success: [200, { message: message }] },
          });
          let display = data.auth_key ? "successPage" : "invitePage";
          setTimeout(() => {
            this.setState({
              [display]: true,
              disableBtn: false,
              auth_key: data.auth_token,
              emloyee_id: data.id,
              validationErrors: null,
            });
          }, 3000);
        }
      })
      .catch((err) => {
        let msg =
          err[0] === 401 ? "Please Enter Valid OTP...!" : "Server Error";

        this.setState({
          validationErrors: { err, msg },
          disableBtn: false,
        });
      });
  };

  inviteSubmit = () => {
    const { auth_key, inviteValidation } = this.state;
    if (!auth_key || inviteValidation.phone) {
      return;
    }

    const { fin_number, ...data } = this.state.inviteData;
    const finNumber = fin_number.substr(5);
    const requestParams = {
      invites: {
        invitees: [
          {
            ...data,
            country_code: data.country_code.value.replace("+", ""),
            fin_number: finNumber,
          },
        ],
        purpose: data.visitor_type,
        visiting_time: data.visiting_time,
        count: 0,
        remarks: data.remarks,
      },
    };
    this.setState({ disableBtn: true });

    createInvite(requestParams, auth_key)
      .then((data) => {
        this.setState({
          validationErrors: { success: [200, { message: data.message }] },
        });

        let display = data.auth_key ? "" : "successPage";
        this.setState({
          [display]: true,
          successPage: true,
          disableBtn: false,
          validationErrors: null,
        });
      })
      .catch((err) => {
        this.setState({
          validationErrors: { err },
          disableBtn: false,
        });
      });
  };

  render() {
    const {
      data,
      validation,
      validationErrors,
      inviteData,
      inviteValidation,
      otpPage,
      invitePage,
      successPage,
      disableBtn,
    } = this.state;
    const {
      handleInputChange,
      otpSubmit,
      validateOtp,
      inviteSubmit,
      handleInviteeChange,
      handleCustomChange,
      handlePurposeChange,
      handleInviteCustomChange,
      handleVisitingTime,
      handleChangeNric,
    } = this;

    if (successPage) {
      return <SuccessPage />;
    }

    if (invitePage) {
      return (
        <InvitePage
          handleInviteeChange={handleInviteeChange}
          handleInviteCustomChange={handleInviteCustomChange}
          data={inviteData}
          validation={inviteValidation}
          inviteSubmit={inviteSubmit}
          handlePurposeChange={handlePurposeChange}
          validationErrors={validationErrors}
          handleVisitingTime={handleVisitingTime}
          handleChangeNric={handleChangeNric}
        />
      );
    }

    if (otpPage) {
      return (
        <InviteOTP
          data={data}
          handleInputChange={handleInputChange}
          disableBtn={disableBtn}
          otpSubmit={otpSubmit}
          validateOtp={validateOtp}
          validationErrors={validationErrors}
        />
      );
    }

    return (
      <StaffForm
        name={data.name}
        phone={data.phone}
        validation={validation}
        disableBtn={disableBtn}
        otpSubmit={otpSubmit}
        handleCustomChange={handleCustomChange}
        validationErrors={validationErrors}
      />
    );
  }
}

export default Invitation;
