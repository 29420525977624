import React, { Component } from "react";
import "assets/css/common.css";
import { getCookie } from "helpers";
import { createVisit, searchUnitData } from "api/visit";
import { DeliverySuccess } from "assets/icons";
import workflow from "workflow.json";
import SuccessPage from "pages/successPage";
import DeliveryField from "./deliveryField";
import PageNotFound from "pages/errorPage";
import { Deliverytypes } from "config";
class Delivery extends Component {
  constructor(props) {
    super(props);
    this.workflowData =
      workflow && workflow[`${props.about.aliasName}`]["delivery"]["fields"];
    this.state = {
      loader: true,
      message: "",
      data: {
        name:'',
        phone: "",
        type: "",
        block: "",
        number_plate: "",
        mode_of_entry: "car",
        purpose_of_visit: "",
        employer:"",
        fin_number: ""
      },
      validation: {
        phone: false,
      },
      blockList: [],
      success: false,
      loading: false,
      error: false,
      homePage: false,
      tempObj: this.workflowData["temperature"],
      phoneObj: this.workflowData["phone"],
      blockObj: this.workflowData["block"],
      visit_data: {},
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    this.setState({ data: { ...data, [name]: value }, validationErrors: null });
  };
  handleCustomChange = (name, value, valid) => {
    const { data, validation } = this.state;
    this.setState({
      data: { ...data, [name]: value },
      validation: { ...validation, [name]: valid },
    });
  };
  handleChangeNric = (value) => {
    const { data, nricPrier } = this.state;
    let { fin_number } = data;

    let fin = fin_number
      ? value.slice(0, 9)
      : `${nricPrier}${value.slice(0, 4)}`;
    this.setState({ data: { ...data, fin_number: fin } });
  };
  handleChange = (name, value) => {
    const data = { ...this.state.data };
    let { error } = this.state;
    data[name] = value;
    this.setState({ data, error }, () => {
      this.state.data.block && this.setState({ errorMessage: false });
    });
  };

  componentDidMount = () => {
    const { blockList } = this.props.about;
    !blockList.length
      ? searchUnitData("", getCookie("vms_token"))
          .then(({ data }) => {
            let units = data.map((value) => ({
              label: value.company_name,
              value: value.id,
            }));
            this.setState({ blockList: units, loader: false });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loader: true, homePage: true });
          })
      : this.setState({ blockList: blockList, loader: false });
  };

  requestParams = () => {
    const { data } = this.state;
    return {
      visits: {
        ...data,
        visitor_type: "delivery",
        mode_of_entry:
          data.mode_of_entry === "bike" ? "car" : data.mode_of_entry,
      },
    };
  };

  formSubmit = () => {
    if (this.state.validation.phone){
      return;
    }
    this.setState({ loading: true });
    createVisit(this.requestParams(), getCookie("vms_token"))
      .then(({ data }) => {
        this.setState({ loading: false, success: true, visit_data: data });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      handleChange,
      handleInputChange,
      handleCustomChange,
      formSubmit,
      handleChangeNric
    } = this;
    const {
      homePage,
      loader,
      showqr,
      visit_data,
      success,
      ...rest
    } = this.state;

    const deliveryData = {
      ...rest,
      formSubmit,
      handleCustomChange,
      handleInputChange,
      handleChange,
      Deliverytypes,
      handleChangeNric
    };

    let about = this.props.about;

    if (homePage) {
      return <PageNotFound />;
    }

    if (success) {
      return (
        <SuccessPage
          content={
            workflow[`${this.props.about.aliasName}`]["delivery"][
              "success_message"
            ]["content"]
          }
          head={
            workflow[`${this.props.about.aliasName}`]["delivery"][
              "success_message"
            ]["title"]
          }
          compo={<DeliverySuccess />}
          subHeading={"Visitor Self-Checkin Service"}
          condos={about}
          showqr={showqr}
          visit_data={visit_data}
        />
      );
    }
    if (loader) {
      return <div id="loader" />;
    }
    return <DeliveryField {...deliveryData} />;
  }
}
export default Delivery;
