import React, { Component } from "react";
import "assets/css/common.css";
import { createVisit, searchUnitData } from "api/visit";
import { getCookie } from "helpers";
import { Success } from "assets/icons";
import {
  AirConService,
  AirConServiceFill,
  Maintenance,
  MaintenanceFill,
  Electrical,
  ElectricalFill,
  Plumbing,
  PlumbingFill,
  HouseKeep,
  HouseKeepFill,
  Others,
  OthersFill,
} from "assets/icons";
import workflow from "workflow.json";
import SuccessPage from "pages/successPage";
import PageNotFound from "pages/errorPage";
import ContractorField from "./contractorField";

class Contractor extends Component {
  constructor(props) {
    super(props);
    this.workflowData =
      workflow[`${props.about.aliasName}`]["contractor"]["fields"];
    this.state = {
      loader: true,
      message: "",
      data: {
        name: "",
        phone: "",
        mode_of_entry: "car",
        number_plate: "",
        fin_number: "",
        employer: "",
        type: "Air-Con",
        block: "",
        sub_type: "Air-Con",
        purpose_of_visit: "",
      },
      validation: {
        phone: false,
      },
      blockList: [],
      loading: false,
      success: false,
      nricPrier: "XXXXX",
      errorPage: false,
      tempObj: this.workflowData["temperature"],
      nameObj: this.workflowData["name"],
      phoneObj: this.workflowData["phone"],
      blockObj: this.workflowData["block"],
      nricObj: this.workflowData["nric"],
      companyObj: this.workflowData["company"],
      visit_data: {},
    };
  }

  handleChange = (name, value) => {
    let { data } = this.state;
    data[name] = value;

    this.setState({ data }, () => {
      this.state.data.block && this.setState({ errorMessage: false });
    });
  };

  handleInviteeChange = (e) => {
    const { name, value } = e.target;
    const { inviteData } = this.state;
    this.setState({ inviteData: { ...inviteData, [name]: value } });
  };

  handleCustomChange = (name, value, valid) => {
    const { data, validation } = this.state;
    this.setState({
      data: { ...data, [name]: value },
      validation: { ...validation, [name]: valid },
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    this.setState({ data: { ...data, [name]: value }, validationErrors: null });
  };

  componentDidMount = () => {
    const { blockList } = this.props.about;
    !blockList.length
      ? searchUnitData("", getCookie("vms_token"))
          .then(({ data }) => {
            let units = data.map((value) => ({
              label: value.company_name,
              value: value.id,
            }));
            this.setState({ blockList: units, loader: false });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ errorPage: true });
          })
      : this.setState({ blockList: blockList, loader: false });
  };

  requestParams = () => {
    const { data } = this.state;
    return {
      visits: {
        ...data,
        visitor_type: "contractor",
        mode_of_entry:
          data.mode_of_entry === "bike" ? "car" : data.mode_of_entry,
        sub_type: data.type || data.sub_type,
      },
    };
  };

  formSubmit = () => {
    if (this.state.validation.phone){
      return
    }
    this.setState({ loading: true });
    createVisit(this.requestParams(), getCookie("vms_token"))
      .then(({ data }) => {
        this.setState({ loading: false, success: true, visit_data: data });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      handleChange,
      handleInviteeChange,
      handleCustomChange,
      handleInputChange,
      formSubmit,
    } = this;
    const types = [
      {
        name: "Air-Con",
        image: <AirConService />,
        imageFill: <AirConServiceFill />,
      },
      {
        name: "Electrical",
        image: <Electrical />,
        imageFill: <ElectricalFill />,
      },
      {
        name: "Maintenance",
        image: <Maintenance />,
        imageFill: <MaintenanceFill />,
      },
      { name: "Plumbing", image: <Plumbing />, imageFill: <PlumbingFill /> },
      {
        name: "House Keeping",
        image: <HouseKeep />,
        imageFill: <HouseKeepFill />,
      },
      { name: "Others", image: <Others />, imageFill: <OthersFill /> },
    ];
    const { errorPage, loader, visit_data, success, ...rest } = this.state;

    const contractorData = {
      ...rest,
      types,
      handleChange,
      handleInputChange,
      handleCustomChange,
      handleInviteeChange,
      formSubmit,
    };

    if (errorPage) {
      return <PageNotFound />;
    }

    if (success) {
      return (
        <SuccessPage
          content={
            workflow[`${this.props.about.aliasName}`]["contractor"][
              "success_message"
            ]["content"]
          }
          head={
            workflow[`${this.props.about.aliasName}`]["contractor"][
              "success_message"
            ]["title"]
          }
          compo={<Success />}
          subHeading={"Contractor Self-Checkin Service"}
          condos={this.props.about}
          visit_data={visit_data}
        />
      );
    }
    if (loader) {
      return <div id="loader" />;
    } else {
      return <ContractorField {...contractorData} />;
    }
  }
}
export default Contractor;
