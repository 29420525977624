
import { Head } from "components";
import React, { Component } from "react"
import { 
    Guest, 
    Delivery, 
    Contractor 
} from "assets/icons/purpose";
import { Button } from "@material-ui/core";

class Webcheckin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            message: "",
        };
    }
    onClick = (e) => {
        this.props.history.push(`/webcheckin/${e}`);
    };
    render() {
        const styles = {
            borderRadius: "12px",
            border: "1px solid lightgray",
            padding: "10px 23px",
            background: "white",
          };
        return (
            <React.Fragment>
                <Head subHeading={"Self-Checkin Service"} />
                <div className="main-container">
                    <div className="content-title">
                        <p className="webindex-sub-title" style={{ margin: "10px 0px" }}>
                            Please Select your Visitor Type
                        </p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-evenly",
                            paddingBottom: "8%",
                            width: "108%",
                        }}
                    >
                        <Button
                            className="button-with-img"
                            variant="contained"
                            type="submit"
                            name="visitor"
                            onClick={() => {
                                this.onClick("visitor");
                            }}
                        >
                            <div style={{ ...styles, padding: "19px 27px 8px" }}>
                                <Guest />
                                <p className="web-index-para">Visitor</p>
                            </div>
                        </Button>
                        <Button
                            className="button-with-img"
                            variant="contained"
                            type="submit"
                            name="contractor"
                            onClick={() => {
                                this.onClick("contractor");
                            }}
                        >
                            <div style={{ ...styles, padding: "23px 25px 9px" }}>
                                <Contractor />
                                <p className="web-index-para">Contractor</p>
                            </div>
                        </Button>
                        <Button
                            className="button-with-img"
                            variant="contained"
                            type="submit"
                            onClick={() => {
                                this.onClick("delivery");
                            }}
                        >
                            <div style={{ ...styles, padding: "17px 25px 8px" }}>
                                <Delivery />
                                <p className="web-index-para">Delivery</p>
                            </div>
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Webcheckin