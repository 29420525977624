import React, { Component } from "react";
import Invited from "./invited";
import { fetchInviteDetails } from "api/invite";
import PageNotFound from "pages/errorPage";
import Expired from "pages/expiredPage";
import moment from "moment";
class QRInvitations extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.invitee_id = this.props.match.params.invitee_id;
    this.state = {
      data: {},
      visible: false,
      errorPage: false,
      showQR: false,
      expired: false,
    };
  }
  componentDidMount() {
    this.fetchInviteData();
  }
  showQRPage = () => {
    this.setState({ showQR: true });
    this.timer = setInterval(() => {
      if (!this.showQR) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        return;
      }
      this.fetchInviteData();
    }, 240000);
  };

  fetchInviteData = () => {
    fetchInviteDetails(this.invitee_id)
      .then(({ data }) => {
        this.computeStatus(data);
        this.setState({
          data,
          visible: true,
        });
      })
      .catch(() => {
        this.setState({ errorPage: true });
      });
  };

  computeStatus = (data) => {
    let validTime =
      moment(data.invite.visiting_time)
        .utc()
        .endOf("day")
        .format() <=
      moment()
        .utc()
        .format();
    if (validTime) {
      this.setState({ showQR: false, visible: false, expired: true });
    }
  };

  render() {
    const { data, visible, errorPage, showQR, expired } = this.state;
    let pageNotFound = {
      title: "Sorry...!!!",
      message: "Couldn't  Find  Invitation",
    };

    if (expired) {
      return <Expired
        guest={data?.invite?.status}
      />;
    }

    if (errorPage) {
      return <PageNotFound data={pageNotFound} />;
    }

    if (!visible) {
      return <div id="loader" />;
    } else {
      return (
        <Invited data={data} enabled={showQR} showQRPage={this.showQRPage} />
      );
    }
  }
}
export default QRInvitations;
