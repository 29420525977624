import React from "react";
import "assets/css/common.css";
import Head from "components/head";

const SuccessPage = ({
  content,
  image,
  textColor,
  data,
  compo,
  imgComponent,
}) => {
  return (
    <React.Fragment>
      <Head subHeading={"Building Management System"} />
      <div className="main-root">
        <div className="main-container">
          {imgComponent && imgComponent}
          {image && (
            <img
              alt="sucess"
              className="head-icon"
              style={{ marginBottom: 0 }}
              src={require("../assets/img/" + image)}
            />
          )}
          {compo && compo}
          {content &&
            content.map((msg, i) => (
              <p key={i} className="content-title" style={{ color: textColor }}>
                {" "}
                {msg}{" "}
              </p>
            ))}
          {data && data}
        </div>
      </div>
    </React.Fragment>
  );
};
export default SuccessPage;
