import React from "react";
import { Warn, Info, Success, Error } from "assets/icons/purpose";

const toast = {
  success: {
    icon: <Success />,
    bgColor: "#EBF7EE",
    color: "#3FBF62",
  },
  warning: {
    icon: <Warn />,
    bgColor: "#F8F3D6",
    color: "#916A29",
    border: "#FFCC00",
  },
  info: {
    icon: <Info />,
    bgColor: "#CDE8F5",
    color: "#4178A4",
  },
  error: {
    icon: <Error />,
    bgColor: "#F2DEDF",
    color: "#A6443F"
  },
};
const styles = {
  validationBlock: {
    padding: "13px 30px 16px 50px",
    margin: "20px auto 30px",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    height: 35,
    width: "78%"
  },
  text: {
    letterSpacing: 0.5,
    paddingLeft: 20,
    fontSize: 14,
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
};
const CustomToast = ({ type, message }) => {
  return (
    <div
      style={{
        ...styles.validationBlock,
        backgroundColor: toast[type].bgColor,
        border: "1px solid",
        borderColor: toast[type].border || toast[type].color,
      }}
    >
      <div>{toast[type].icon}</div>
      <p
        style={{
          ...styles.text,
          color: toast[type].color,
        }}
      >
        {message}
      </p>
    </div>
  );
};

const Toast = (data) => {
  const {
    msg: { msg, err, success },
  } = data;

  const { message } = err?.[1] || success?.[1];
  const status = err?.[0] || success?.[0];

  switch (status) {
    case 200:
      return (
        <CustomToast
          type="success"
          message={msg || message || "Saved Successfully.!!!"}
        />
      );
    case 201:
      return (
        <CustomToast
          type="success"
          message={msg || message || "Saved Successfully.!!!"}
        />
      );
    case 400:
      return (
        <CustomToast
          type="error"
          message={msg || message || "Bad Request.!!!"}
        />
      );
    case 401:
      return (
        <CustomToast
          type="error"
          message={msg || message || "Access Denied.!!!"}
        />
      );
    case 403:
      return (
        <CustomToast
          type="error"
          message={msg || message || "Access Denied.!!!"}
        />
      );
    case 404:
      return (
        <CustomToast
          type="warning"
          message={msg || message || "Not Found.!!!"}
        />
      );
    case 422:
      return (
        <CustomToast
          type="warning"
          message={msg || message || "Unprocessable Entity"}
        />
      );
    case 429:
      return (
        <CustomToast
          type="warning"
          message={msg || message || "Too Many Request.!!!"}
        />
      );
    case 500:
      return (
        <CustomToast
          type="error"
          message={msg || message || "Internal Server Error.!!!"}
        />
      );
    case 502:
      return (
        <CustomToast
          type="error"
          message={msg || message || "Could not fetch data from remote.!!!"}
        />
      );
    default:
      return <CustomToast type="error" message={"Something is not right.!!"} />;
  }
};

export default Toast;
