import React from "react";
import {
  SimpleTextField,
  CustomFormControl,
  SimplePhoneField,
  SimpleNRICField,
} from "components/formField";
import Asynchronous from "components/autoComplete";
import { Head, SubmitButton, Toast } from "components";

const ContractorField = ({
  data: {
    name,
    type,
    sub_type,
    phone,
    fin_number,
    mode_of_entry,
    number_plate,
    employer,
    purpose_of_visit
  },
  types,
  handleChange,
  handleInputChange,
  handleCustomChange,
  validation,
  blockObj,
  blockList,
  validationErrors,
  loading,
  formSubmit,
}) => {
  const styles = {
    text: {
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "22px",
    },
    input: {
      width: "100%",
      marginBottom: 15,
    },
  };
  return (
    <React.Fragment>
      <Head subHeading={"Contractor Self-Checkin Service"} />
      <div className="main-root">
        <div className="main-container">
          <div className="head-content-title">
            <p style={{ margin: "5px 0px" }}>Hello there,</p>
            <p style={{ margin: "5px 0px" }}>Kindly fill your details</p>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formSubmit();
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="form-container" style={{ width: "100%" }}>
              <p
                className="custom-text"
                style={{ margin: 15, textAlign: "start" }}
              >
                Contractor type :
              </p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                {types.map((value, i) => (
                  <React.Fragment key={i}>
                    <div
                      key={i}
                      className="img-container"
                      style={{ padding: "4%" }}
                      onClick={() => handleChange("sub_type", value["name"])}
                    >
                      {sub_type === value["name"]
                        ? value["imageFill"]
                        : value["image"]}
                      <p
                        style={{
                          margin: "0px",
                          fontSize: "14px",
                          height: "17px",
                        }}
                      >
                        {value.name}
                      </p>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                  textAlign: "center",
                }}
              >
                {sub_type === "Others" && (
                  <SimpleTextField
                    id="sub_type"
                    label="Contractor Type"
                    defaultValue={sub_type}
                    onChange={(e) => handleChange("sub_type", e.target.value)}
                    required
                    helperText=" "
                  />
                )}
              </div>
              <p
                className="custom-text"
                style={{ margin: "18px 4px", textAlign: "start" }}
              >
                Contractor Details :
              </p>

              <SimpleTextField
                id="name"
                name="name"
                label="Name"
                value={name}
                onChange={handleInputChange}
                required
                helperText=" "
              />
              <SimplePhoneField
                id="phone"
                name="phone"
                label="Phone"
                value={phone}
                onChange={handleCustomChange}
                inputProps={{
                  maxLength: 8,
                  type: "tel",
                }}
                error={validation.phone}
                required
                helperText=" "
              />
              <SimpleNRICField
                name="fin_number"
                value={fin_number}
                onChange={handleCustomChange}
                error={validation.fin_number}
                required
                helperText=" "
              />
              <CustomFormControl
                variant="outlined"
                className={"text-field"}
                style={{ ...styles.input, ...styles.text }}
              >
                <Asynchronous
                  label="Company"
                  name="company"
                  handleChange={(e) => handleChange("company_id", e.target.value)}
                  dataPromise={blockList}
                  required={blockObj.mandatory}
                  helperText=" "
                />
              </CustomFormControl>
              <SimpleTextField
                id="purpose_of_visit"
                name="purpose_of_visit"
                label="Purpose of visit"
                value={purpose_of_visit}
                onChange={handleInputChange}
                // error={validation.phone}
                helperText=" "
              />
              <SimpleTextField
                id="employer"
                name="employer"
                label="Employer"
                value={employer}
                onChange={handleInputChange}
                // error={validation.phone}
                helperText=" "
              />
              {/* {companyObj.show && (
                <SimpleTextField
                  id="employer"
                  name="employer"
                  label="Company"
                  value={employer}
                  onChange={handleInputChange}
                  required
                  helperText=" "
                />
              )} */}
              

              <p
                className="custom-text"
                style={{ margin: "", textAlign: "start" }}
              >
                Vehicle Details :
              </p>
              <div className="radio-group">
                <input
                  type="radio"
                  id="car"
                  name="car"
                  onClick={(e) => {
                    handleChange("mode_of_entry", e.target.id);
                  }}
                  checked={mode_of_entry === "car" ? true : false}
                />
                <label className="label" htmlFor="car">
                  Car/M-Bike
                </label>
                <input
                  type="radio"
                  id="escooter"
                  name="selector"
                  onClick={(e) => {
                    handleChange("mode_of_entry", e.target.id);
                  }}
                  checked={mode_of_entry === "escooter" ? true : false}
                />
                <label className="label" htmlFor="escooter" name="escooter">
                  Bicycle
                </label>
                <input
                  type="radio"
                  id="walkin"
                  name="selector"
                  onChange={(e) => {
                    handleChange("mode_of_entry", e.target.id);
                  }}
                  checked={mode_of_entry === "walkin" ? true : false}
                />
                <label className="label" htmlFor="walkin">
                  Walk In
                </label>
              </div>
              {(mode_of_entry === "car" || mode_of_entry === "bike") && (
                <SimpleTextField
                  id="number_plate"
                  name="number_plate"
                  label="Number Plate"
                  defaultValue={number_plate}
                  onChange={handleInputChange}
                  required
                />
              )}
            </div>
            {validationErrors && <Toast msg={validationErrors} />}
            <div
              style={{
                textAlign: "center",
                paddingBottom: "8%",
                paddingTop: "4%",
                width: "100%",
              }}
            >
              <SubmitButton text="SUBMIT" loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ContractorField;
