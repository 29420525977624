import React from "react";
import { CustomFormControl } from "components/formField";
import ReactFlagsSelect from "react-flags-select";

const CountrySelect = ({ onSelect, selected }) => {
    const customLabels = {
        SG : { secondary : "+65"},
        MY : { secondary : "+60"},
        ID : { secondary : "+62"},
    };
    const handleChange = (countryCode) => {
        onSelect ({
            target : {
                name : "country_code",
                value:{
                    label: countryCode,
                    value : customLabels[countryCode].secondary,
                },
            },
        });
    };
    return (
        <CustomFormControl variant="outlined" >
            <div style={{minWidth:"100px",paddingRight:"1%"}}>
                <ReactFlagsSelect 
                    required
                    showSelectedLabel
                    showSecondarySelectedLabel
                    showOptionLabel
                    showSecondaryOptionLabel
                    className="menu-flag"
                    countries={["SG","MY","ID"]}
                    customLabels={customLabels}
                    selected={selected?.label}
                    placeholder="Select Country"
                    onSelect={handleChange}
                />
            </div>
        </CustomFormControl>
    )
}


export default CountrySelect;
