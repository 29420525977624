import React from "react";
import { Head } from "components";
import AccessDenied from "assets/icons/AccessIcon";

export default function PermissionDenied() {
  const styles = {
    container: {
      backgroundColor: "#D5D5D5",
      minHeight: "100vh",
      padding: "15px 12px 0px",
    },
    success: {
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: 20,
      margin: "50px 20px",
      textAlign: "center",
    },
  };
  return (
    <div style={styles.container}>
      <Head/>
      <div className="main-container">
        <h3 style={styles.success}>You Don&apos;t have Access to this URL.</h3>
        <AccessDenied />
        <div style={{ height: "20px" }}></div>
        <h3 style={styles.success}>Contact Security.</h3>
      </div>
    </div>
  );
}
