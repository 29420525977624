import React from "react";
import { color, alias_name } from "config";

const Head = ({ subHeading }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        paddingTop:"12px"
      }}
    >
      <header
        className="header"
        style={{
          backgroundColor: color,
          color: "#ffff",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1>{alias_name}</h1>
          <h3 style={{ marginBottom: "0px" }}>{subHeading}</h3>
        </div>
      </header>
    </div>
  );
};
export default Head;
