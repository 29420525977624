import React from "react";
import { NotFound } from "components/IndexIcon";
import Head from "components/head";
import TicketBorder from "components/ticketBorder";

const styles = {
  image: {
    display: "flex",
    justifyContent: "center",
    margin: "50px auto",
  },

  content: {
    fontFamily: "Montserrat",
    lineHeight: "34px",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
    margin: "10px 15px",
  },
};
export const PageNotFound = () => {
  return (
    <React.Fragment>
      <Head subHeading={"Building Management System"} />
        <div className="main-container" style={{padding:"0px"}}>
          <div style={{paddingBottom:"10%"}}>
            <NotFound />
            <h1 style={{ ...styles.content, fontSize: 20 }}>Error: 404</h1>
            <h1 style={{ ...styles.content, fontWeight: 100 }}>Page Not Found</h1>
          </div>
          <TicketBorder />
        </div>
    </React.Fragment>
  );
};
export default PageNotFound;
