import React from "react";

const AccessDenied = () => {
  return (
    <svg
      width="175"
      height="176"
      viewBox="0 0 175 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M145.339 0.169434H20.7626C17.4864 0.169434 14.8304 2.87116 14.8304 6.20392V169.135C14.8304 172.468 17.4864 175.169 20.7626 175.169H145.339C148.615 175.169 151.271 172.468 151.271 169.135V6.20392C151.271 2.87116 148.615 0.169434 145.339 0.169434Z"
        fill="#9FC9D3"
      />
      <path
        d="M29.661 93.704C32.9372 93.704 35.5932 91.0022 35.5932 87.6695C35.5932 84.3367 32.9372 81.635 29.661 81.635C26.3847 81.635 23.7288 84.3367 23.7288 87.6695C23.7288 91.0022 26.3847 93.704 29.661 93.704Z"
        fill="#4482C3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6949 90.6867H2.9661C1.32797 90.6867 0 89.3358 0 87.6695C0 86.0031 1.32797 84.6522 2.9661 84.6522H26.6949C28.333 84.6522 29.661 86.0031 29.661 87.6695C29.661 89.3358 28.333 90.6867 26.6949 90.6867Z"
        fill="#ECF0F1"
      />
      <path
        d="M29.661 63.5314C32.9372 63.5314 35.5932 60.8296 35.5932 57.4969C35.5932 54.1641 32.9372 51.4624 29.661 51.4624C26.3847 51.4624 23.7288 54.1641 23.7288 57.4969C23.7288 60.8296 26.3847 63.5314 29.661 63.5314Z"
        fill="#4482C3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6949 60.5142H2.9661C1.32797 60.5142 0 59.1634 0 57.497C0 55.8306 1.32797 54.4797 2.9661 54.4797H26.6949C28.333 54.4797 29.661 55.8306 29.661 57.497C29.661 59.1634 28.333 60.5142 26.6949 60.5142Z"
        fill="#ECF0F1"
      />
      <path
        d="M29.661 33.3591C32.9372 33.3591 35.5932 30.6574 35.5932 27.3246C35.5932 23.9919 32.9372 21.2902 29.661 21.2902C26.3847 21.2902 23.7288 23.9919 23.7288 27.3246C23.7288 30.6574 26.3847 33.3591 29.661 33.3591Z"
        fill="#4482C3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6949 30.342H2.9661C1.32797 30.342 0 28.9911 0 27.3247C0 25.6584 1.32797 24.3075 2.9661 24.3075H26.6949C28.333 24.3075 29.661 25.6584 29.661 27.3247C29.661 28.9911 28.333 30.342 26.6949 30.342Z"
        fill="#ECF0F1"
      />
      <path
        d="M29.661 154.049C32.9372 154.049 35.5932 151.347 35.5932 148.014C35.5932 144.681 32.9372 141.98 29.661 141.98C26.3847 141.98 23.7288 144.681 23.7288 148.014C23.7288 151.347 26.3847 154.049 29.661 154.049Z"
        fill="#4482C3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6949 151.031H2.9661C1.32797 151.031 0 149.681 0 148.014C0 146.348 1.32797 144.997 2.9661 144.997H26.6949C28.333 144.997 29.661 146.348 29.661 148.014C29.661 149.681 28.333 151.031 26.6949 151.031Z"
        fill="#ECF0F1"
      />
      <path
        d="M29.661 123.876C32.9372 123.876 35.5932 121.175 35.5932 117.842C35.5932 114.509 32.9372 111.807 29.661 111.807C26.3847 111.807 23.7288 114.509 23.7288 117.842C23.7288 121.175 26.3847 123.876 29.661 123.876Z"
        fill="#4482C3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6949 120.859H2.9661C1.32797 120.859 0 119.508 0 117.842C0 116.175 1.32797 114.825 2.9661 114.825H26.6949C28.333 114.825 29.661 116.175 29.661 117.842C29.661 119.508 28.333 120.859 26.6949 120.859Z"
        fill="#ECF0F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 24.3073C45.8194 24.3073 44.4915 22.9565 44.4915 21.2901V18.2729C44.4915 16.6065 45.8194 15.2556 47.4576 15.2556C49.0957 15.2556 50.4237 16.6065 50.4237 18.2729V21.2901C50.4237 22.9565 49.0957 24.3073 47.4576 24.3073Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.4237 0.169434V6.20392C50.4237 7.87029 49.0957 9.22116 47.4576 9.22116C45.8194 9.22116 44.4915 7.87029 44.4915 6.20392V0.169434H50.4237Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 39.3936C45.8194 39.3936 44.4915 38.0428 44.4915 36.3764V33.3592C44.4915 31.6928 45.8194 30.3419 47.4576 30.3419C49.0957 30.3419 50.4237 31.6928 50.4237 33.3592V36.3764C50.4237 38.0428 49.0957 39.3936 47.4576 39.3936Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 54.4797C45.8194 54.4797 44.4915 53.1288 44.4915 51.4625V48.4452C44.4915 46.7788 45.8194 45.428 47.4576 45.428C49.0957 45.428 50.4237 46.7788 50.4237 48.4452V51.4625C50.4237 53.1288 49.0957 54.4797 47.4576 54.4797Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 69.566C45.8194 69.566 44.4915 68.2151 44.4915 66.5488V63.5315C44.4915 61.8651 45.8194 60.5143 47.4576 60.5143C49.0957 60.5143 50.4237 61.8651 50.4237 63.5315V66.5488C50.4237 68.2151 49.0957 69.566 47.4576 69.566Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 84.6522C45.8194 84.6522 44.4915 83.3013 44.4915 81.6349V78.6177C44.4915 76.9513 45.8194 75.6005 47.4576 75.6005C49.0957 75.6005 50.4237 76.9513 50.4237 78.6177V81.6349C50.4237 83.3013 49.0957 84.6522 47.4576 84.6522Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 99.7384C45.8194 99.7384 44.4915 98.3875 44.4915 96.7211V93.7039C44.4915 92.0375 45.8194 90.6866 47.4576 90.6866C49.0957 90.6866 50.4237 92.0375 50.4237 93.7039V96.7211C50.4237 98.3875 49.0957 99.7384 47.4576 99.7384Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 114.825C45.8194 114.825 44.4915 113.474 44.4915 111.807V108.79C44.4915 107.124 45.8194 105.773 47.4576 105.773C49.0957 105.773 50.4237 107.124 50.4237 108.79V111.807C50.4237 113.474 49.0957 114.825 47.4576 114.825Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 129.911C45.8194 129.911 44.4915 128.56 44.4915 126.894V123.876C44.4915 122.21 45.8194 120.859 47.4576 120.859C49.0957 120.859 50.4237 122.21 50.4237 123.876V126.894C50.4237 128.56 49.0957 129.911 47.4576 129.911Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 144.997C45.8194 144.997 44.4915 143.646 44.4915 141.98V138.963C44.4915 137.296 45.8194 135.945 47.4576 135.945C49.0957 135.945 50.4237 137.296 50.4237 138.963V141.98C50.4237 143.646 49.0957 144.997 47.4576 144.997Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4576 160.083C45.8194 160.083 44.4915 158.732 44.4915 157.066V154.049C44.4915 152.382 45.8194 151.031 47.4576 151.031C49.0957 151.031 50.4237 152.382 50.4237 154.049V157.066C50.4237 158.732 49.0957 160.083 47.4576 160.083Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.4237 169.135V175.169H44.4915V169.135C44.4915 167.469 45.8194 166.118 47.4576 166.118C49.0957 166.118 50.4237 167.469 50.4237 169.135Z"
        fill="#84B5CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.475 27.3246H65.2542C63.6161 27.3246 62.2881 25.9738 62.2881 24.3074C62.2881 22.641 63.6161 21.2902 65.2542 21.2902H133.475C135.113 21.2902 136.441 22.641 136.441 24.3074C136.441 25.9738 135.113 27.3246 133.475 27.3246Z"
        fill="#35495E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.1864 45.428H65.2542C63.6161 45.428 62.2881 44.0772 62.2881 42.4108C62.2881 40.7444 63.6161 39.3936 65.2542 39.3936H71.1864C72.8245 39.3936 74.1525 40.7444 74.1525 42.4108C74.1525 44.0772 72.8245 45.428 71.1864 45.428Z"
        fill="#35495E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.8814 45.428H83.0509C81.4128 45.428 80.0848 44.0772 80.0848 42.4108C80.0848 40.7444 81.4128 39.3936 83.0509 39.3936H97.8814C99.5196 39.3936 100.848 40.7444 100.848 42.4108C100.848 44.0772 99.5196 45.428 97.8814 45.428Z"
        fill="#35495E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.9491 63.5314H65.2542C63.6161 63.5314 62.2881 62.1806 62.2881 60.5142C62.2881 58.8478 63.6161 57.4969 65.2542 57.4969H91.9491C93.5872 57.4969 94.9152 58.8478 94.9152 60.5142C94.9152 62.1806 93.5872 63.5314 91.9491 63.5314Z"
        fill="#35495E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.1186 99.7383H65.2542C63.6161 99.7383 62.2881 98.3875 62.2881 96.7211C62.2881 95.0547 63.6161 93.7039 65.2542 93.7039H77.1186C78.7567 93.7039 80.0847 95.0547 80.0847 96.7211C80.0847 98.3875 78.7567 99.7383 77.1186 99.7383Z"
        fill="#35495E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.0847 81.6349H65.2542C63.6161 81.6349 62.2881 80.2841 62.2881 78.6177C62.2881 76.9513 63.6161 75.6005 65.2542 75.6005H80.0847C81.7228 75.6005 83.0508 76.9513 83.0508 78.6177C83.0508 80.2841 81.7228 81.6349 80.0847 81.6349Z"
        fill="#35495E"
      />
      <path
        d="M166.102 84.6522H100.847C95.933 84.6522 91.9491 88.7048 91.9491 93.7039V154.049C91.9491 159.048 95.933 163.101 100.847 163.101H166.102C171.016 163.101 175 159.048 175 154.049V93.7039C175 88.7048 171.016 84.6522 166.102 84.6522Z"
        fill="#F0C419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.678 84.6522V66.5488C115.678 56.5505 123.646 48.4453 133.475 48.4453C143.303 48.4453 151.271 56.5505 151.271 66.5488V84.6522H163.136V66.5488C163.136 49.885 149.856 36.3763 133.475 36.3763C117.093 36.3763 103.814 49.885 103.814 66.5488V84.6522H115.678Z"
        fill="#95A5A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.482 141.289L140.593 124.438C144.686 121.325 146.36 115.896 144.748 110.964C143.136 106.031 138.6 102.702 133.489 102.702C128.379 102.702 123.843 106.031 122.231 110.964C120.619 115.896 122.293 121.325 126.386 124.438L122.482 141.289C122.275 142.184 122.481 143.126 123.04 143.848C123.6 144.57 124.453 144.993 125.356 144.997H141.593C142.499 144.998 143.356 144.576 143.919 143.854C144.482 143.131 144.689 142.186 144.482 141.289V141.289Z"
        fill="#E57E25"
      />
    </svg>
  );
};

export default AccessDenied;
