import React from "react";
import Head from "components/head";
import RightCard from "./rightCard";
import ContractorResponse from "./leftCard";
import TicketBorder from "components/ticketBorder";
import { Divider } from "components/QRComponent";
const Invited = ({ id, showQR, expired, data }) => {
  return (
    <React.Fragment>
      <Head subHeading={"Contractor Registration"} />
      <div className="qr-root">
        <div className="qr-container">
          <div className="qr-innercontainer">
            <ContractorResponse
              id={id}
              showQR={showQR}
              expired={expired}
              data={data}
            />
          </div>
          <Divider />
          <div style={{ display: "flex" }}>
            <RightCard data={data} />
          </div>
          <TicketBorder />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Invited;
