import React, { Component } from "react";
import Head from "components/head";
import "assets/css/common.css";
import SubmitButton from "components/submitButton";
import { OTPImg } from "assets/icons";
import { SimpleOTPField } from "components/formField";
import { Toast } from "components";

const styles = {
  phone: {
    fontFamily: "Montserrat",
    color: "#aca9a9",
  },
  image: {
    margin: "10px 30px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontSize: "22px",
    fontFamily: "Montserrat",
    color: "#000",
    textAlign: "center",
  },
  labelBlock: {
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "space-between",
    marginBottom: "4px",
    marginTop: "10px",
  },
  link: {
    textDecoration: "underline",
    color: "#451BAB",
    marginRight: "10px",
    cursor: "pointer",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
  },
  timer: {
    display: "flex",
    justifyContent: "end",
    textAlign: "end",
    marginRight: "10px",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

class InviteOTP extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      seconds: 30,
      resendDisable: false,
    };
  }

  componentDidMount = () => {
    this.retryCounter();
  };

  retryCounter = () => {
    this.timer = setInterval(() => {
      const { seconds } = this.state;
      if (seconds > 1) {
        this.setState({ seconds: seconds - 1 });
      } else {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
          this.setState({ seconds: 0 });
        }
      }
    }, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
    this.timer = null;
  };
  resendOtp = () => {
    this.setState({ resendDisable: true });
    this.props.otpSubmit();
  };

  render() {
    const {
      data: { phone, otp_token },
      disableBtn,
      handleInputChange,
      validateOtp,
      validationErrors,
    } = this.props;
    const { seconds, resendDisable } = this.state;
    const onSubmits = (e) => {
      e.preventDefault();
      validateOtp();
    };
    return (
      <React.Fragment>
        <Head subHeading={"Smart QR Invite"} />
          <div className="main-container">
            <div>
              <p style={styles.heading}>Please enter the OTP code</p>
            </div>
            <div style={styles.image}>
              <OTPImg />
            </div>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                paddingBottom: "4%",
              }}
            >
              <p style={styles.phone}>Enter the OTP code sent to - {phone}</p>
            </div>
            <form onSubmit={onSubmits} style={styles.form}>
              <div>
                <SimpleOTPField
                  required
                  name="otp_token"
                  value={otp_token}
                  onChange={handleInputChange}
                />
                {!resendDisable && (
                  <div
                    style={{
                      ...styles.labelBlock,
                      visibility: resendDisable ? "hidden" : "visible",
                    }}
                  >
                    <p className="otp_receive">Didn&apos;t Receive OTP yet? </p>
                    {seconds === 0 ? (
                      <p style={styles.link} onClick={this.resendOtp}>
                        RESEND OTP
                      </p>
                    ) : (
                      <p style={styles.timer}>
                        {seconds < 10 ? `00 : 0${seconds}` : `00 : ${seconds}`}
                      </p>
                    )}
                  </div>
                )}
                {validationErrors && <Toast msg={validationErrors} />}
                <div style={{ textAlign: "center" }}>
                  <SubmitButton text="VERIFY" loading={disableBtn} />
                </div>
              </div>
            </form>
          </div>
      </React.Fragment>
    );
  }
}
export default InviteOTP;
