import React from "react";

export const VisitorHead = () => {
  return (
    <span className="head-content-icon" style={{ marginBottom: "0px" }}>
      <svg viewBox="0 0 170 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M143.835 124.187C143.835 124.187 139.961 119.674 143.595 116.799C143.595 116.799 146.244 115.188 148.414 115.321C148.414 115.321 145.352 120.459 146.337 120.979C147.336 121.484 149.346 115.002 149.346 115.002C149.346 115.002 155.39 114.416 157.333 115.348C157.333 115.348 152.168 119.568 153.26 119.82C154.351 120.073 159.929 115.92 159.929 115.92C159.929 115.92 165.547 116.878 165.254 120.3C164.948 123.734 163.017 124.786 162.165 124.693C161.313 124.599 156.335 122.696 156.228 123.401C156.122 124.094 159.117 126.383 161.22 126.49C161.22 126.49 157.253 130.856 154.617 129.605C151.982 128.34 151.755 126.343 150.118 125.598C148.481 124.852 147.07 124.852 147.749 125.611C148.427 126.37 151.396 127.115 152.341 128.779C153.286 130.443 154.125 131.562 151.396 131.149C148.667 130.736 144.234 128.739 144.048 126.463C143.835 124.187 143.835 124.187 143.835 124.187Z"
          fill="#82B378"
        />
        <path
          d="M162.485 120.406C162.485 120.406 147.216 119.82 143.848 124.187C143.848 124.187 143.103 126.423 140.813 128.46L140.454 129.432C140.454 129.432 143.103 126.636 144.048 126.476C144.035 126.476 142.557 121.058 162.485 120.406Z"
          fill="#82B378"
        />
        <path
          d="M140.8 128.46C140.8 128.46 137.525 130.882 137.299 135.209L137.898 135.262C137.898 135.262 138.949 130.789 140.84 129.032C142.717 127.288 140.8 128.46 140.8 128.46Z"
          fill="#82B378"
        />
        <path
          d="M130.297 118.503C130.297 118.503 136.46 115.055 133.585 110.369C133.585 110.369 131.282 107.494 128.819 106.762C128.819 106.762 130.137 113.737 128.819 113.91C127.501 114.07 127.914 106.03 127.914 106.03C127.914 106.03 121.431 102.915 118.875 103.154C118.875 103.154 122.895 109.97 121.591 109.81C120.273 109.651 115.76 102.742 115.76 102.742C115.76 102.742 109.104 101.504 108.039 105.457C106.974 109.398 108.692 111.368 109.677 111.621C110.662 111.874 116.985 111.78 116.825 112.606C116.665 113.431 112.392 114.736 110.009 114.004C110.009 114.004 112.632 120.486 116.093 120.167C119.541 119.834 120.606 117.704 122.749 117.545C124.879 117.385 126.45 117.957 125.371 118.53C124.306 119.102 120.686 118.716 118.968 120.18C117.238 121.645 115.853 122.55 119.048 123.202C122.256 123.854 128.007 123.455 129.152 120.979C130.297 118.503 130.297 118.503 130.297 118.503Z"
          fill="#A8D29F"
        />
        <path
          d="M111.088 106.682C111.088 106.682 128.34 112.273 130.31 118.503C130.31 118.503 130.23 121.299 131.947 124.493V125.731C131.947 125.731 130.137 121.538 129.152 120.966C129.152 120.979 133.012 115.548 111.088 106.682Z"
          fill="#82B378"
        />
        <path
          d="M131.947 124.507C131.947 124.507 134.61 128.54 133.092 133.466L132.413 133.28C132.413 133.28 133.079 127.862 131.681 125.146C130.283 122.417 131.947 124.507 131.947 124.507Z"
          fill="#82B378"
        />
        <path
          d="M140.108 113.751C140.108 113.751 131.987 109.198 135.781 103.035C135.781 103.035 138.816 99.2407 142.064 98.2689C142.064 98.2689 140.334 107.467 142.064 107.694C143.795 107.907 143.249 97.2971 143.249 97.2971C143.249 97.2971 151.809 93.1838 155.163 93.5032C155.163 93.5032 149.852 102.489 151.596 102.276C153.326 102.063 159.277 92.9708 159.277 92.9708C159.277 92.9708 168.049 91.3467 169.46 96.5384C170.871 101.73 168.595 104.339 167.291 104.659C165.986 104.978 157.653 104.872 157.866 105.963C158.079 107.041 163.71 108.772 166.851 107.8C166.851 107.8 163.39 116.36 158.837 115.921C154.285 115.481 152.887 112.672 150.065 112.459C147.256 112.246 145.193 113.005 146.604 113.764C148.015 114.523 152.781 114.017 155.044 115.947C157.32 117.877 159.157 119.075 154.937 119.941C150.717 120.806 143.129 120.26 141.625 117.012C140.108 113.751 140.108 113.751 140.108 113.751Z"
          fill="#A8D29F"
        />
        <path
          d="M165.44 98.1626C165.44 98.1626 142.704 105.524 140.108 113.751C140.108 113.751 140.214 117.438 137.938 121.658V123.282C137.938 123.282 140.321 117.758 141.625 116.999C141.612 116.999 136.527 109.85 165.44 98.1626Z"
          fill="#82B378"
        />
        <path
          d="M137.938 121.658C137.938 121.658 134.437 126.983 136.434 133.466L137.339 133.226C137.339 133.226 136.46 126.091 138.297 122.497C140.121 118.902 137.938 121.658 137.938 121.658Z"
          fill="#82B378"
        />
        <path
          d="M139.828 147.523C148.614 147.523 155.736 140.401 155.736 131.615H123.92C123.92 140.401 131.042 147.523 139.828 147.523Z"
          fill="#F1A34F"
        />
        <path
          d="M143.742 147.39C143.089 147.47 142.437 147.51 141.771 147.51C132.986 147.51 125.864 140.388 125.864 131.602H129.817C129.817 139.722 135.888 146.418 143.742 147.39Z"
          fill="#FFB973"
        />
        <path
          d="M155.949 148.322H123.694C123.694 147.244 124.573 146.365 125.651 146.365H153.979C155.07 146.365 155.949 147.244 155.949 148.322Z"
          fill="#F1A34F"
        />
        <path
          d="M156.095 131.615H123.401V132.108H156.095V131.615Z"
          fill="#D86713"
        />
        <path
          d="M8.63882 137.18C8.63882 138.857 7.28101 140.201 5.61702 140.201C3.95303 140.201 2.59521 138.844 2.59521 137.18C2.59521 135.502 3.95303 134.158 5.61702 134.158C7.28101 134.158 8.63882 135.516 8.63882 137.18Z"
          fill="#D86713"
        />
        <path
          d="M6.80208 140.201C8.47097 140.201 9.82388 138.848 9.82388 137.179C9.82388 135.51 8.47097 134.157 6.80208 134.157C5.13318 134.157 3.78027 135.51 3.78027 137.179C3.78027 138.848 5.13318 140.201 6.80208 140.201Z"
          fill="#F1A34F"
        />
        <path
          d="M28.8068 145.247C28.8068 146.924 27.449 148.269 25.785 148.269C24.121 148.269 22.7632 146.911 22.7632 145.247C22.7632 143.583 24.121 142.225 25.785 142.225C27.449 142.225 28.8068 143.583 28.8068 145.247Z"
          fill="#D86713"
        />
        <path
          d="M30.0045 145.246C30.0045 146.924 28.6467 148.268 26.9827 148.268C25.3054 148.268 23.9609 146.91 23.9609 145.246C23.9609 143.582 25.3188 142.225 26.9827 142.225C28.6467 142.225 30.0045 143.582 30.0045 145.246Z"
          fill="#F1A34F"
        />
        <path
          d="M3.23438 133.905L6.58898 137.02C6.92177 137.339 7.32113 137.592 7.74711 137.765L30.3108 147.15L34.5041 144.128L3.23438 133.905Z"
          fill="#D86713"
        />
        <path
          d="M50.7312 100.066L49.6529 99.3203C54.6049 92.1585 64.1629 78.0612 64.9483 75.9179C65.4408 74.5734 65.4541 73.4419 64.9616 72.5767C64.1762 71.1789 62.3392 70.8062 62.3258 70.8062L62.2593 70.7929L57.2407 69.2487C51.5033 66.959 49.0539 71.4718 48.9474 71.6581L48.9074 71.7114L35.5156 91.9455L34.4241 91.2266L47.8025 71.0192C47.9357 70.7795 48.6811 69.5016 50.1587 68.5032C51.6364 67.5048 54.139 66.6129 57.6933 68.024L62.5921 69.5415C62.9249 69.6081 65.0548 70.1273 66.0798 71.9377C66.772 73.1624 66.7986 74.6533 66.1597 76.3839C65.1347 79.206 51.3169 99.2271 50.7312 100.066Z"
          fill="#D86713"
        />
        <path
          d="M49.214 94.9805L60.3028 102.595C60.3028 102.595 63.2181 105.55 59.8768 111.434C56.9216 116.626 41.1336 146.045 41.1336 146.045C41.1336 146.045 37.8456 152.168 25.0396 144.501L49.214 94.9805Z"
          fill="#D86713"
        />
        <path
          d="M52.342 106.269L34.4242 139.123C33.692 140.454 32.667 141.519 31.4556 142.265C29.2059 143.649 26.3572 143.942 23.7746 142.864L12.4995 138.125L12.2199 138.005L5.16459 135.036C4.45906 134.743 3.80677 134.357 3.23436 133.905C3.10124 133.798 2.96812 133.692 2.84832 133.585C0.745034 131.722 -0.279983 128.939 0.0661267 126.197C0.212558 125.026 0.611915 123.881 1.27751 122.803L20.4067 92.2118C22.4435 88.9504 26.437 87.4861 30.1111 88.6575L32.1745 89.3231L32.667 89.4829L33.0797 89.616L39.9885 91.8258L40.8405 92.092L47.5098 94.2219C48.5614 94.5547 49.4799 95.0739 50.2787 95.7262C50.3319 95.7661 50.3852 95.806 50.4384 95.8593L50.7579 96.1388C53.407 98.6681 54.2323 102.795 52.342 106.269Z"
          fill="#F1A34F"
        />
        <path
          opacity="0.4"
          d="M31.4424 142.264C29.1927 143.649 26.3439 143.942 23.7614 142.864L12.4862 138.124L12.2067 138.005L5.15136 135.036C4.44583 134.743 3.79355 134.357 3.22114 133.905C3.08802 133.798 2.9549 133.692 2.83509 133.585C0.745122 131.721 -0.799059 127.568 0.492196 124.427C0.492196 124.427 24.4137 134.291 29.0462 137.193C31.4424 138.67 31.4424 140.8 31.4424 142.264Z"
          fill="#D86713"
        />
        <path
          d="M33.0796 89.6158C33.013 89.6292 26.4369 90.5743 24.5599 93.0636C22.6297 95.6328 3.62031 127.062 3.42063 127.382C3.40732 127.408 1.88976 130.59 3.54044 134.144L3.22095 133.905C3.08783 133.798 2.95471 133.692 2.83491 133.585C1.38391 130.004 2.95471 127.275 3.03459 127.155C3.81999 125.864 22.257 95.3932 24.2005 92.8107C25.9044 90.5477 30.6568 89.5892 32.1744 89.3496L32.6669 89.5094L33.0796 89.6158Z"
          fill="#D86713"
        />
        <path
          d="M40.8408 92.0918C39.869 92.2782 36.0751 93.2233 33.7455 96.9906C31.0166 101.397 13.578 134.424 13.3916 134.756L13.3783 134.783L13.365 134.796C13.3517 134.81 12.1669 136.487 12.7793 138.231L12.4997 138.111L12.2202 137.991C11.6877 136.181 12.8325 134.703 13.0056 134.504C13.5514 133.479 30.6572 101.104 33.3595 96.7377C35.3696 93.5029 38.418 92.2649 40.0021 91.8123L40.8408 92.0918Z"
          fill="#D86713"
        />
        <path
          d="M50.7445 96.1387C50.7178 96.1387 49.3733 96.2852 47.9889 96.9508C46.6045 97.6164 44.8872 98.8144 43.556 100.958C40.8271 105.364 24.0141 138.524 23.8411 138.857L23.8278 138.883L23.8145 138.91C23.8012 138.923 22.8161 140.973 23.4284 142.717L22.7229 142.424C22.3368 140.907 23.2687 138.857 23.455 138.63C23.9875 137.565 40.4677 105.098 43.1833 100.718C45.7392 96.6047 49.5065 95.8459 50.2919 95.7261C50.3451 95.766 50.3984 95.8059 50.4516 95.8592L50.7445 96.1387Z"
          fill="#D86713"
        />
        <path
          d="M56.5883 136.993C56.5883 136.993 54.1788 139.216 54.658 140.8C55.1239 142.384 57.2938 143.436 57.5867 145.379C57.8795 147.31 59.051 148.375 60.1026 148.375C61.1542 148.375 65.1345 148.375 65.1345 148.375C65.1345 148.375 65.9066 147.017 64.0296 146.604C62.1526 146.191 61.4471 143.915 61.5669 141.812C61.6867 139.695 61.3939 136.886 61.3939 136.886L57.4003 135.955L56.5883 136.993Z"
          fill="#233862"
        />
        <path
          d="M100.185 148.361H86.8195C86.6997 148.335 86.6199 148.308 86.5533 148.282C85.6614 147.842 85.941 144.421 86.0475 143.369C86.0741 143.17 86.0874 143.063 86.0874 143.063C86.0874 143.063 90.7865 139.309 91.4255 142.185C91.4388 142.278 91.4654 142.358 91.492 142.451C92.3041 145.14 95.9515 146.365 98.0548 146.697C99.6655 146.964 100.078 147.896 100.185 148.361Z"
          fill="#233862"
        />
        <path
          d="M97.4957 71.4053C97.4957 71.4053 96.7768 80.2976 95.9515 90.9871C94.8067 105.51 93.4489 123.348 93.3024 126.716C93.0628 132.587 92.131 141.972 92.131 141.972C92.131 141.972 89.9079 144.195 85.4484 143.263C85.4484 143.263 81.4681 129.871 80.3366 115.694C80.3233 115.468 80.2967 115.228 80.2834 114.988C79.2317 100.558 77.5811 70.1672 77.5811 70.1672L97.4957 71.4053Z"
          fill="#2B478B"
        />
        <path
          d="M97.4957 71.4053C97.4957 71.4053 96.7768 80.2976 95.9515 90.9871L80.3366 115.694C80.3233 115.468 80.2967 115.228 80.2834 114.988C79.2317 100.558 77.5811 70.1672 77.5811 70.1672L97.4957 71.4053Z"
          fill="#233862"
        />
        <path
          d="M94.2477 72.457C94.2477 72.457 81.4017 104.658 72.9486 119.794C64.4955 134.93 61.8598 139.15 61.8598 139.15C61.8598 139.15 57.0142 137.219 56.575 135.888C56.575 135.888 59.8097 106.669 65.4673 99.5467C68.6622 95.5265 72.4161 83.4393 72.5892 79.2194C72.7622 74.9995 73.734 66.6396 73.734 66.6396L94.2477 72.457Z"
          fill="#2B478B"
        />
        <path
          d="M107.24 63.5645L107.972 65.0288C107.972 65.0288 111.7 65.5479 110.674 69.4483C110.674 69.4483 110.115 71.0457 109.09 70.5399C108.065 70.034 108.571 70.074 107.094 69.3951C105.616 68.7162 104.764 67.3983 105.097 66.2002L104.857 64.9223L107.24 63.5645Z"
          fill="#AB7041"
        />
        <path
          d="M87.6583 22.7501C88.2307 19.3822 88.244 17.3854 88.244 17.3854L81.7212 12.1272C81.9608 19.3556 77.3416 22.8167 77.3416 22.8167C77.3416 22.8167 86.0209 27.0898 91.0528 25.5057C91.0395 25.519 89.8148 23.9349 87.6583 22.7501Z"
          fill="#9C5F33"
        />
        <path
          d="M89.7749 17.2655C89.7749 17.2655 81.4816 18.6367 80.7095 13.4716C79.9374 8.29331 78.34 4.99196 83.5982 3.88707C88.8564 2.78218 90.1876 4.60591 90.8665 6.2699C91.5454 7.93389 91.9181 16.773 89.7749 17.2655Z"
          fill="#AB7041"
        />
        <path
          d="M80.7627 13.8178C80.7627 13.8178 80.9358 6.88226 88.4836 5.76406C93.6619 5.00528 90.5203 0.678907 89.9346 0C89.9346 0 85.981 0.758779 81.9341 1.63736C73.4278 3.51434 79.8708 12.7927 80.7627 13.8178Z"
          fill="#233862"
        />
        <path
          d="M106.362 65.6013C107.466 64.4697 108.292 62.9788 108.292 62.9788C108.292 62.9788 98.867 51.2776 98.1082 50.0663C97.3361 48.8549 91.6253 29.3795 91.6253 29.3795L77.1553 26.4509C77.1553 26.4509 89.2292 51.3708 91.2393 54.4059C94.5406 59.4245 104.431 66.8925 104.431 66.8925C104.431 66.8925 105.496 66.4932 106.362 65.6013Z"
          fill="#4A75CB"
        />
        <path
          d="M98.055 72.1507C97.9086 72.2705 97.7223 72.3504 97.5226 72.3504C95.6323 72.3237 90.1345 73.3754 85.4886 72.8962C81.5616 72.5234 78.1005 71.352 74.3732 69.688C73.8806 69.4617 72.7358 68.8626 72.7624 68.3035C72.7757 67.6912 72.8423 66.8259 72.9089 65.7077C73.1751 61.8606 73.6277 55.2446 73.1884 47.6035C73.1085 46.1792 73.0686 44.7681 73.0553 43.3836C73.0153 33.0403 75.0388 23.8817 79.1521 20.8066C79.4982 20.5537 81.6947 21.6586 83.6915 22.6437C85.5285 23.5489 86.8065 23.5223 88.5504 23.1762L90.84 24.8268C92.9566 26.7837 95.2596 29.4461 96.2713 33.4929C97.1232 36.9407 97.6291 43.144 97.9086 49.587C98.0284 52.5023 98.1349 55.4442 98.1616 58.2397V58.3462C98.1882 58.6258 98.1882 58.9187 98.1882 59.1982C98.2814 66.36 98.1749 72.0442 98.055 72.1507Z"
          fill="#739AF0"
        />
        <path
          d="M72.9089 65.7076C73.1752 61.8604 73.6278 55.2444 73.1885 47.6034C73.1086 46.179 73.0687 44.7679 73.0554 43.3835C74.7726 39.2169 76.1704 36.1951 76.1704 36.1951H80.4967C80.51 36.2084 83.6916 57.8802 72.9089 65.7076Z"
          fill="#4A75CB"
        />
        <path
          d="M64.4953 67.0522L63.67 68.4633C63.67 68.4633 65.2807 71.8711 61.4602 73.1491C61.4602 73.1491 59.8228 73.5617 59.6764 72.4302C59.53 71.2854 59.7829 71.738 59.5433 70.1273C59.3037 68.5165 59.9293 67.0922 61.1141 66.7061L62.0459 65.8142L64.4953 67.0522Z"
          fill="#AB7041"
        />
        <path
          d="M62.3122 67.4382C63.8697 67.7443 65.5603 67.6245 65.5603 67.6245C65.5603 67.6245 70.1928 53.3275 70.7785 52.023C71.3776 50.7184 84.5297 35.2633 84.5297 35.2633L79.0452 21.552C79.0452 21.552 64.8281 45.3138 63.3771 48.6417C60.9943 54.1662 60.1689 66.5329 60.1689 66.5329C60.1689 66.5329 61.0875 67.1985 62.3122 67.4382Z"
          fill="#739AF0"
        />
        <g clipPath="url(#clip0)">
          <path
            d="M165.068 149.107H5.58511C5.5138 149.107 5.45776 148.947 5.45776 148.761C5.45776 148.574 5.5138 148.428 5.58511 148.428H165.068C165.14 148.428 165.196 148.574 165.196 148.761C165.201 148.947 165.14 149.107 165.068 149.107Z"
            fill="#283250"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="159.743"
              height="0.678907"
              fill="white"
              transform="translate(5.45776 148.428)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export const PickupHead = () => {
  return (
    <span className="head-content-icon" style={{ marginBottom: "0px" }}>
      <svg viewBox="0 0 180 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M167.202 66.319L127.537 67.7792C127.505 67.2979 127.483 66.8112 127.483 66.319C127.483 55.3543 136.375 46.4568 147.345 46.4568C158.315 46.4568 167.202 55.3489 167.202 66.319Z"
          fill="#32373A"
        />
        <path
          d="M48.8759 65.8705L9.52327 66.0181C9.5178 66.0291 9.51233 66.04 9.51233 66.0509C9.51233 65.9908 9.51233 65.9306 9.51233 65.8705C9.51233 54.9988 18.3278 46.1887 29.1941 46.1887C40.0603 46.1887 48.8759 55.0042 48.8759 65.8705Z"
          fill="#32373A"
        />
        <path
          d="M29.1941 82.949C38.3787 82.949 45.8243 75.5033 45.8243 66.3187C45.8243 57.1341 38.3787 49.6885 29.1941 49.6885C20.0095 49.6885 12.5638 57.1341 12.5638 66.3187C12.5638 75.5033 20.0095 82.949 29.1941 82.949Z"
          fill="#3E4347"
        />
        <path
          d="M29.1941 76.2335C34.6699 76.2335 39.1088 71.7945 39.1088 66.3188C39.1088 60.843 34.6699 56.4041 29.1941 56.4041C23.7184 56.4041 19.2794 60.843 19.2794 66.3188C19.2794 71.7945 23.7184 76.2335 29.1941 76.2335Z"
          fill="#DAE5EF"
        />
        <path
          d="M29.1942 70.8578C31.701 70.8578 33.7332 68.8256 33.7332 66.3188C33.7332 63.812 31.701 61.7798 29.1942 61.7798C26.6873 61.7798 24.6552 63.812 24.6552 66.3188C24.6552 68.8256 26.6873 70.8578 29.1942 70.8578Z"
          fill="#3E4347"
        />
        <path
          d="M147.35 82.949C156.535 82.949 163.981 75.5033 163.981 66.3187C163.981 57.1341 156.535 49.6885 147.35 49.6885C138.166 49.6885 130.72 57.1341 130.72 66.3187C130.72 75.5033 138.166 82.949 147.35 82.949Z"
          fill="#3E4347"
        />
        <path
          d="M147.35 76.2335C152.826 76.2335 157.265 71.7945 157.265 66.3188C157.265 60.843 152.826 56.4041 147.35 56.4041C141.875 56.4041 137.436 60.843 137.436 66.3188C137.436 71.7945 141.875 76.2335 147.35 76.2335Z"
          fill="#DAE5EF"
        />
        <path
          d="M147.35 70.8578C149.857 70.8578 151.889 68.8256 151.889 66.3188C151.889 63.812 149.857 61.7798 147.35 61.7798C144.843 61.7798 142.811 63.812 142.811 66.3188C142.811 68.8256 144.843 70.8578 147.35 70.8578Z"
          fill="#3E4347"
        />
        <path
          d="M179.998 63.3166C180.053 65.0666 178.937 66.647 177.264 67.1556C175.126 67.8064 171.768 68.1454 167.24 66.5322L167.202 66.5376C167.415 55.8956 158.758 46.6153 147.629 46.4621C136.675 46.309 127.592 55.1683 127.488 66.122C127.483 66.5431 127.493 66.9642 127.515 67.3743C127.652 69.972 125.618 72.1594 123.015 72.1594H54.6617C51.4188 72.1594 48.8102 69.4962 48.8758 66.2533C48.8758 66.1822 48.8758 66.1165 48.8758 66.0455C48.9743 55.1847 40.1369 46.227 29.2706 46.1832C18.3661 46.1395 9.5123 54.9714 9.5123 65.865C9.5123 65.9251 9.5123 65.9853 9.5123 66.0455C8.40763 68.7853 5.72251 68.2603 5.87016 65.7939V55.9229C5.87016 55.9229 3.52957 47.8074 6.5264 42.47C9.52324 37.1326 13.1818 38.0294 13.1818 38.0294C13.1818 38.0294 14.7896 28.7272 49.1547 27.2616L67.7263 6.34939C67.7263 6.34939 66.1896 3.2049 73.9442 1.37837C81.6933 -0.448172 124.322 -1.10988 136.9 4.22755C149.478 9.56497 160.223 30.1819 160.223 30.1819C160.223 30.1819 179.052 31.7897 179.998 63.3166Z"
          fill="#FFD93B"
        />
        <path
          d="M144.31 29.6512H117.054C114.998 29.6512 113.313 28.027 113.231 25.9763L112.695 12.0147C112.608 9.73428 114.527 7.8804 116.808 8.0554C124.852 8.67883 131.026 9.69053 134.034 10.9647C138.71 12.9499 143.582 18.3693 147.394 23.5755C149.242 26.0966 147.438 29.6512 144.31 29.6512Z"
          fill="#3E4347"
        />
        <path
          d="M107.566 25.6809C107.648 27.852 105.914 29.6512 103.743 29.6512H64.4342C61.246 29.6512 59.4632 25.9763 61.4265 23.4661C65.9928 17.6256 70.6904 12.5014 75.5903 8.50931C75.6013 8.50931 75.6067 8.50384 75.6177 8.50384C77.6083 8.03353 85.6472 7.37183 97.2791 7.37183C99.3189 7.37183 101.32 7.3937 103.267 7.43198C105.291 7.47026 106.931 9.08899 107.008 11.1124L107.566 25.6809Z"
          fill="#3E4347"
        />
        <path
          d="M58.5717 40.7415H55.4546V43.8586H58.5717V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M61.6287 43.7988H58.5116V46.916H61.6287V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M61.6287 37.6245H58.5116V40.7417H61.6287V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M64.7459 40.7415H61.6288V43.8586H64.7459V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M67.8028 43.7988H64.6857V46.916H67.8028V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M67.8028 37.6245H64.6857V40.7417H67.8028V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M70.92 40.7415H67.8029V43.8586H70.92V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M73.9605 43.7988H70.8434V46.916H73.9605V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M73.9605 37.6245H70.8434V40.7417H73.9605V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M77.0941 40.7415H73.9769V43.8586H77.0941V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M80.1512 43.7988H77.0341V46.916H80.1512V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M80.1512 37.6245H77.0341V40.7417H80.1512V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M83.2683 40.7415H80.1511V43.8586H83.2683V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M86.3253 43.7988H83.2081V46.916H86.3253V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M86.3253 37.6245H83.2081V40.7417H86.3253V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M89.4425 40.7415H86.3253V43.8586H89.4425V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M92.4994 43.7988H89.3822V46.916H92.4994V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M92.4994 37.6245H89.3822V40.7417H92.4994V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M95.6165 40.7415H92.4994V43.8586H95.6165V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M98.6735 43.7988H95.5564V46.916H98.6735V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M98.6735 37.6245H95.5564V40.7417H98.6735V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M101.796 40.7415H98.6791V43.8586H101.796V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M104.848 43.7988H101.73V46.916H104.848V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M104.848 37.6245H101.73V40.7417H104.848V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M107.965 40.7415H104.848V43.8586H107.965V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M111.022 43.7988H107.905V46.916H111.022V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M111.022 37.6245H107.905V40.7417H111.022V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M114.139 40.7415H111.022V43.8586H114.139V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M117.196 43.7988H114.079V46.916H117.196V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M117.196 37.6245H114.079V40.7417H117.196V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M120.313 40.7415H117.196V43.8586H120.313V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M123.343 43.7988H120.226V46.916H123.343V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M123.343 37.6245H120.226V40.7417H123.343V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M126.487 40.7415H123.37V43.8586H126.487V40.7415Z"
          fill="#3E4347"
        />
        <path
          d="M129.522 43.7988H126.405V46.916H129.522V43.7988Z"
          fill="#3E4347"
        />
        <path
          d="M129.522 37.6245H126.405V40.7417H129.522V37.6245Z"
          fill="#3E4347"
        />
        <path
          d="M77.477 63.5244V55.1135H74.4747V53.4019H82.5192V55.1135H79.5223V63.5244H77.477Z"
          fill="#E24B4B"
        />
        <path
          d="M91.9144 63.5244H89.6941L88.8081 61.2275H84.7613L83.9246 63.5244H81.759L85.7019 53.4019H87.8621L91.9144 63.5244ZM88.1519 59.5213L86.7574 55.7643L85.3902 59.5213H88.1519Z"
          fill="#E24B4B"
        />
        <path
          d="M91.969 63.5244L95.4252 58.2416L92.2916 53.4019H94.6814L96.7103 56.6557L98.7009 53.4019H101.069L97.9189 58.3182L101.375 63.5244H98.9087L96.6666 60.0244L94.4135 63.5244H91.969Z"
          fill="#E24B4B"
        />
        <path
          d="M102.365 63.5244V53.4019H104.41V63.5244H102.365Z"
          fill="#E24B4B"
        />
        <path
          d="M80.4029 7.9244L64.0789 29.635C64.1992 29.646 64.3195 29.6514 64.4398 29.6514H72.5991L89.273 7.48145C85.702 7.57988 82.7052 7.74394 80.4029 7.9244Z"
          fill="#5A5F63"
        />
        <path
          d="M95.2174 7.37744C93.9705 7.38291 92.7729 7.39932 91.6245 7.42666L74.9067 29.6568H78.4668L95.2174 7.37744Z"
          fill="#5A5F63"
        />
        <path
          d="M125.131 8.93066L113.188 24.8117L113.231 25.9765C113.308 28.0273 114.998 29.6515 117.054 29.6515H118.082L132.541 10.4291C130.693 9.86034 128.177 9.35722 125.131 8.93066Z"
          fill="#5A5F63"
        />
        <path
          d="M136.829 12.5234C135.997 11.9601 135.166 11.4789 134.34 11.1016L120.39 29.6513H123.95L136.829 12.5234Z"
          fill="#5A5F63"
        />
        <path
          d="M45.4854 69.6819C45.7096 68.5937 45.8244 67.4726 45.8244 66.3187C45.8244 66.1765 45.8244 66.0288 45.8189 65.8867L48.8759 65.8757C48.8759 65.9359 48.8759 65.996 48.8759 66.0507C48.8759 66.1218 48.8759 66.1874 48.8759 66.2585C48.8541 67.4288 49.1767 68.528 49.7564 69.4468C48.3236 69.5343 46.8963 69.6108 45.4854 69.6819Z"
          fill="#3E4347"
        />
        <path
          d="M13.3732 43.1862C13.056 45.2424 12.006 47.0252 10.5185 48.2994C9.03103 49.5736 7.10059 50.3283 5.02249 50.3283C4.91312 47.8072 5.19202 44.865 6.52638 42.4807C9.52321 37.1433 13.1818 38.0401 13.1818 38.0401C13.1818 38.0401 13.8599 40.0307 13.3732 43.1862Z"
          fill="#E9EFF4"
        />
        <path
          d="M176.504 46.2213L177.073 46.2869C174.885 40.2987 171.795 36.5308 168.853 34.1628C168.667 34.2667 168.487 34.3652 168.301 34.4746C167.076 34.9667 166.217 35.8527 166.217 36.7495V37.9964C166.212 42.2401 170.291 45.4994 176.504 46.2213Z"
          fill="#E24B4B"
        />
        <path
          d="M179.856 83.428H0.143504C0.0631417 83.428 0 83.1927 0 82.9182C0 82.6437 0.0631417 82.428 0.143504 82.428H179.856C179.937 82.428 180 82.6437 180 82.9182C180.005 83.1927 179.937 83.428 179.856 83.428Z"
          fill="#3E4347"
        />
      </svg>
    </span>
  );
};

export const DeliveryHead = () => {
  return (
    <span className="head-content-icon" style={{ marginBottom: "0px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 150">
        <path
          fill="#2152FF"
          d="M7.744 82.946l-.545.745 14.614 10.682.545-.746L7.744 82.947z"
        ></path>
        <path
          fill="#494948"
          d="M144.808 136.531c7.43-7.429 7.43-19.475 0-26.905-7.429-7.43-19.475-7.43-26.905 0s-7.43 19.476 0 26.905c7.43 7.43 19.476 7.43 26.905 0z"
        ></path>
        <path
          fill="#6D6C6C"
          d="M137.432 138.12c8.3-3.353 12.311-12.8 8.957-21.1-3.353-8.3-12.8-12.31-21.1-8.957-8.3 3.354-12.31 12.801-8.957 21.101 3.354 8.3 12.8 12.31 21.1 8.956z"
        ></path>
        <path
          fill="#8A8888"
          d="M143.112 124.995c1.062-6.494-3.34-12.619-9.834-13.682-6.494-1.063-12.619 3.34-13.682 9.834-1.063 6.493 3.34 12.619 9.834 13.682 6.493 1.062 12.619-3.34 13.682-9.834z"
        ></path>
        <path
          fill="#494948"
          d="M137.252 123.973a5.495 5.495 0 10-10.848-1.766 5.495 5.495 0 0010.848 1.766zM43.409 136.547c7.43-7.43 7.43-19.476 0-26.906-7.43-7.429-19.476-7.429-26.906 0-7.43 7.43-7.43 19.476 0 26.906s19.476 7.43 26.906 0z"
        ></path>
        <path
          fill="#6D6C6C"
          d="M36.025 138.127c8.3-3.354 12.31-12.801 8.957-21.101-3.354-8.3-12.8-12.31-21.1-8.956-8.3 3.353-12.31 12.8-8.958 21.1 3.354 8.3 12.8 12.31 21.1 8.957z"
        ></path>
        <path
          fill="#8A8888"
          d="M41.702 125.083c1.096-6.488-3.275-12.636-9.763-13.731-6.488-1.096-12.635 3.276-13.73 9.763-1.096 6.488 3.275 12.636 9.763 13.731 6.487 1.096 12.635-3.275 13.73-9.763z"
        ></path>
        <path
          fill="#494948"
          d="M35.386 123.98a5.496 5.496 0 10-10.848-1.768 5.496 5.496 0 0010.848 1.768z"
        ></path>
        <path
          fill="#2152FF"
          d="M30 83.427h-1.663v4.894H30v-4.894zM38.958 83.427h-1.662v4.894h1.662v-4.894z"
        ></path>
        <path
          fill="#19283A"
          d="M47.547 72.99c2.356 4.018 2.402 8.497 15.701 9.19 12.653.646 17.41 2.816 22.581 2.355 3.694-.37 4.941-9.652-.185-10.067-5.818-.416-22.304-1.57-22.304-1.57l-15.793.092z"
        ></path>
        <path
          fill="#19283A"
          d="M78.81 83.334l10.62 34.864 8.36-.138s-9.698-37.635-10.668-41.237c-3.279 1.339-8.312 6.51-8.312 6.51z"
        ></path>
        <path
          fill="#839DF9"
          d="M70.22 84.766s-38.789.738-41.56.83c-2.816.14-3.601.74-3.278 1.848.185.692.139 1.431-1.108 2.863-1.062 1.154-3.048 3.186-4.387 4.294-3.463 2.864 26.229 23.367 27.707 24.613a353.361 353.361 0 005.356 4.526s43.916.046 48.672.046 21.103-17.178 23.228-26.552c-7.943-4.387-11.545-14.316-13.207-18.102-1.663-3.787-6.742-17.132-7.158-18.702-.416-1.57-3.879-.554-2.401 2.77 1.477 3.371 7.25 19.118 8.035 22.581.831 3.464.97 7.99-1.709 13.623-2.724 5.68-8.774 13.484-10.113 14.962-1.293 1.477-4.248 2.401-8.22 2.401H71.192c-3.187 0-10.806-6.096-11.083-13.392-.554-12.19 10.113-18.61 10.113-18.61z"
        ></path>
        <path
          fill="#451BAB"
          d="M2.847 118.152S4.51 99.219 22.15 92.847c13.023-4.71 26.968 1.247 31.771 13.161 3.14 7.757.831 16.3.046 18.194-7.804 1.57-45.439-1.616-51.119-6.05z"
        ></path>
        <path
          fill="#451BAB"
          d="M104.393 123.647H51.38a1.39 1.39 0 00-1.385 1.386c0 .785.646 1.385 1.385 1.385h53.013c.785 0 1.385-.646 1.385-1.385s-.6-1.386-1.385-1.386z"
        ></path>
        <path
          fill="#2152FF"
          d="M48.333 85.504c-3.787-.138-4.11-4.34-.231-4.525 3.879-.185 23.781 1.432 25.305 1.57 1.524.139 1.478 2.91-.554 2.91-3.371.045-19.626.184-24.52.045z"
        ></path>
        <path
          fill="#451BAB"
          d="M41.868 55.258H4.232a.562.562 0 00-.554.554v27.199h38.744V55.812a.534.534 0 00-.554-.554z"
        ></path>
        <path
          fill="#839DF9"
          d="M3.678 59.737v23.274h38.744v-9.143C20.626 78.67 8.896 72.482 3.678 59.738z"
        ></path>
        <path
          stroke="#2152FF"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3"
          d="M22.842 54.842v28.076"
        ></path>
        <path
          fill="#2152FF"
          d="M43.3 84.489H2.107c-.6 0-1.108-.508-1.108-1.109 0-.6.508-1.108 1.108-1.108h41.237c.6 0 1.108.508 1.108 1.108-.046.6-.554 1.109-1.154 1.109z"
        ></path>
        <path
          fill="#A7D4F5"
          d="M110.95 58.12v10.114c2.632-.323 2.632-9.79 0-10.113z"
        ></path>
        <path
          fill="#451BAB"
          d="M105.732 63.202c0 2.447 1.708 4.433 3.971 4.94v-9.881c-2.263.508-3.971 2.493-3.971 4.94z"
        ></path>
        <path
          fill="#A0A09F"
          d="M110.811 58.12c-.369 0-.738.047-1.108.14v9.881c.37.093.739.139 1.108.139h.139V58.167c-.046-.046-.092-.046-.139-.046z"
        ></path>
        <path
          fill="#451BAB"
          d="M71.56 115.428s6.142.646 7.065.831c.97.185 11.453 3.14 11.453 5.865H79.594c-3.325 0-10.482.323-11.452 0-.277-.093-.877-1.016-.416-1.986.878-1.663 3.233-4.71 3.833-4.71z"
        ></path>
        <path
          fill="#2152FF"
          d="M90.124 122.124H68.189v1.477h21.935v-1.477z"
        ></path>
        <path
          fill="#E1A57C"
          d="M72.381 28.172l-5.306-3.852-4.15 5.717 5.305 3.853 4.151-5.718zM79.595 57.613c1.755.693 8.312 3.417 18.517 3.094 1.155-.508 6.096-.877 6.096-.877s-.693-1.386-2.725-1.524c-4.294-.277-6.65-.785-10.02-1.201-4.156-.508-7.112-1.57-9.42-2.54-1.71-.739-4.618-2.863-4.618-2.863l-3.371 3.325c0-.046 1.893 1.108 5.541 2.586z"
        ></path>
        <path
          fill="#451BAB"
          d="M58.815 32.677c2.494-1.432 5.495-.416 7.758 4.48 2.263 4.894 5.449 10.205 11.775 13.622-.554 2.724-4.525 4.848-4.525 4.848s-9.467-4.017-12.7-9.374c-4.155-7.019-4.802-12.19-2.308-13.576z"
        ></path>
        <path
          fill="#183A58"
          d="M47.547 72.99c.047 4.156 2.402 8.497 15.701 9.19 12.653.646 20.503.83 25.629.323 3.694-.37 4.94-9.651-.185-10.067-5.818-.416-22.304-1.57-22.304-1.57l-18.84 2.124z"
        ></path>
        <path
          fill="#183A58"
          d="M81.304 80.148l-9.79 35.141 8.404 1.201s10.76-33.756 11.868-37.312c-3.463-.647-10.482.97-10.482.97z"
        ></path>
        <path
          fill="#451BAB"
          d="M47.547 72.99c0-4.433 2.54-23.135 5.034-30.708 2.54-7.666 7.019-14.315 11.96-12.884 8.081 2.401 5.357 11.775 4.895 18.702-.462 6.927-3.002 22.858-3.002 22.858s-8.681 1.894-18.886 2.032z"
        ></path>
        <path
          fill="#EFC1A1"
          d="M82.643 59.737c1.708.785 3.37 3.925 17.686 2.77 2.124.185 5.126-.553 5.126-.553s-.185-3.556-3.233-3.371c-1.939.138-4.94.692-8.22.276-3.97-.507-6.834-1.662-9.096-2.724-1.663-.785-4.48-3.186-4.48-3.186l-3.186 3.879c0 .046 1.893 1.339 5.403 2.909z"
        ></path>
        <path
          fill="#2152FF"
          d="M62.232 31.43c2.355-1.709 5.264-.6 7.527 4.987 2.263 5.588 5.403 11.637 11.545 15.47-.462 3.186-4.249 5.68-4.249 5.68s-9.143-4.433-12.33-10.529c-4.11-7.989-4.848-13.9-2.493-15.608z"
        ></path>
        <path
          fill="#EFC1A1"
          d="M65.326 26.489s10.436 7.435 13.576 4.941c.462-.37 1.755-3.74 2.586-6.188 1.201-3.648 2.125-8.45 1.432-11.73C81.858 8.065 76.178.26 70.96 1.693c-5.264 1.43-17.27 7.711-5.634 24.797z"
        ></path>
        <path
          fill="#EFC1A1"
          d="M80.519 17.854s-.047 0 0 0l.277-.647c1.293 1.662 2.863 2.91 4.294 4.202-3.325 2.817-6.326-.138-5.31-3.786.277 0 .554.138.739.23.138.093.138.047 0 0z"
        ></path>
        <path
          fill="url(#paint0_linear)"
          d="M83.474 11.112s4.48-3.556.185-6.558c1.247 3.14-.508 4.71-.508 4.71s.184-1.062-.554-2.031c-.046 1.662-1.062 1.477-2.54-.462-1.478-1.94-1.986-3.14-3.88-4.249-2.03-1.2-4.109-2.031-7.48-1.523-3.37.554-6.234 1.708-9.236 5.356-2.955 3.648-2.4 9.005-1.108 12.006 1.34 3.095 4.018 6.65 6.327 9.005 1.94-.739 2.909-5.91 3.555-8.45 1.293-2.125 3.51-.832 4.341-2.54.323-.647.462-1.755.462-3.002 3.648 1.755 11.775 1.247 13.345-4.664-.739 1.478-2.909 2.402-2.909 2.402z"
        ></path>
        <path
          fill="#EFC1A1"
          d="M67.589 17.808c.415 1.754 2.17 3.694 3.279 3.37 1.154-.277 1.708-1.939 1.292-3.648-.415-1.754-1.57-3.97-3.047-3.51-1.109.324-1.94 2.033-1.524 3.788z"
        ></path>
        <path
          fill="url(#paint1_linear)"
          d="M81.766 14.436c.092.785.277 3.602-1.94 3.694-2.17.093-1.431-3.278-1.431-3.278s2.355-.37 3.37-.416z"
        ></path>
        <path
          fill="url(#paint2_linear)"
          d="M81.258 14.205c-2.355-.184-4.11.924-1.94.924 2.217.046 3.187-.831 1.94-.924z"
        ></path>
        <path
          stroke="#452F20"
          strokeMiterlimit="10"
          strokeWidth="0.821"
          d="M79.133 17.853c.693-1.2 2.032-.692 1.94.37"
        ></path>
        <path
          fill="#275E7A"
          d="M80.703 116.998l-9.65-1.293a.62.62 0 01-.509-.693l.277-1.939a.619.619 0 01.693-.508l9.651 1.293a.619.619 0 01.508.692l-.277 1.94c-.092.323-.37.554-.693.508z"
        ></path>
        <path
          fill="#451BAB"
          d="M121.017 97.418c20.133-7.019 26.46 12.191 26.46 12.191s-10.667-2.447-18.795 3.187c-9.374 6.465-14.13 11.683-21.149 9.605-1.386-2.956 1.154-20.688 13.484-24.983z"
        ></path>
        <path
          fill="#3F3E3E"
          d="M102.869 65.648a2.77 2.77 0 100-5.54 2.77 2.77 0 000 5.54z"
        ></path>
        <path
          fill="#2E2E2D"
          d="M102.869 64.216a1.34 1.34 0 100-2.679 1.34 1.34 0 000 2.68z"
        ></path>
        <path
          stroke="#D8E4EE"
          strokeMiterlimit="10"
          strokeWidth="1.054"
          d="M75.762 20.809l-4.848-4.526M73.269 28.567l-10.437-3.971"
        ></path>
        <path
          fill="#451BAB"
          d="M58.723 22.795s-7.066-15.24 4.94-21.196C75.67-4.358 82.09 8.249 82.09 8.249L72.068 13.65s.185 1.57.323 4.018c.139 2.216-.277 6.234-3.971 7.527-4.064 1.385-8.081.092-9.697-2.401z"
        ></path>
        <path
          fill="#839DF9"
          d="M56.783 11.25c-.739 5.818 1.94 11.544 1.94 11.544 1.616 2.494 5.633 3.787 9.65 2.402 3.695-1.247 4.064-5.265 3.972-7.527v-.37c-5.634.785-11.498-1.2-15.562-6.049z"
        ></path>
        <path
          stroke="#2152FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2.136"
          d="M58.722 22.795s2.31 3.832 8.266 2.724c3.972-.739 5.357-3.833 5.357-6.742 0-2.91-.277-5.126-.277-5.126l10.02-5.402"
        ></path>
        <path
          fill="#D8E4EE"
          d="M83.058 17.807c-.23.462-1.431 1.524-3.001 2.263-1.57.693-3.787.37-4.618.185-1.755 2.078-3.048 7.296-2.401 8.681 1.57 1.155 4.017 2.078 5.91 2.263.324.046.693-.092.924-.277 2.678-2.401 5.495-8.681 5.403-9.513-.185-.923-2.078-2.447-2.217-3.602z"
        ></path>
        <path
          fill="#A4C0D4"
          d="M75.9 20.994c2.31.277 4.25.046 6.004-1.293.462-.37.924-.832 1.34-1.34-.093-.184-.186-.369-.186-.554-.23.462-1.431 1.524-3.001 2.263-1.57.693-3.787.37-4.618.185-1.755 2.078-3.048 7.296-2.401 8.681 1.57 1.155 4.017 2.078 5.91 2.263.324.046.647-.046.924-.277-1.2-.277-4.941-1.2-6.095-2.217-.555-1.754.83-6.372 2.124-7.711z"
        ></path>
        <path
          fill="#839DF9"
          d="M53.92 106.008c-1.616-4.064-4.34-7.389-7.666-9.882C49.95 110.21 28.8 129.512 3.31 115.659c-.323 1.57-.416 2.54-.416 2.54 5.68 4.479 43.315 7.619 51.12 6.049.738-1.986 3-10.529-.093-18.24z"
        ></path>
        <path
          fill="#2152FF"
          d="M29.492 91.276c2.032-.184 4.11-.092 6.141.185 2.032.277 4.018.878 5.911 1.663.462.23.97.37 1.386.646l1.385.739 1.293.831c.23.139.416.277.647.462l.6.462c1.616 1.247 3.14 2.724 4.387 4.387 1.247 1.662 2.309 3.463 3.094 5.402.785 1.94 1.246 3.972 1.431 6.05.231 2.078.185 4.156-.046 6.188-.139 1.016-.277 2.032-.508 3.047-.23 1.016-.462 2.032-.877 3.002l-.093.231-.277.046c-1.108.231-2.216.277-3.279.323-1.108.046-2.17.046-3.278.046-2.17 0-4.34-.092-6.511-.184-2.17-.093-4.34-.231-6.465-.416-2.17-.185-4.295-.369-6.465-.6-4.295-.508-8.59-1.062-12.838-1.847-2.124-.416-4.248-.831-6.326-1.432a34.19 34.19 0 01-3.094-.97c-1.016-.369-2.032-.785-2.91-1.477.878.646 1.94.97 2.956 1.339 1.016.323 2.078.6 3.14.877 2.125.508 4.249.878 6.373 1.201 4.248.693 8.543 1.154 12.838 1.57 4.294.369 8.589.739 12.93.877 2.17.093 4.294.139 6.464.139 2.125 0 4.341-.046 6.373-.416l-.37.277c.37-.877.6-1.893.832-2.863.23-.969.369-1.985.508-2.955.23-1.986.277-4.018.092-6.003-.185-1.986-.6-3.971-1.34-5.819a25.263 25.263 0 00-2.862-5.264c-1.2-1.616-2.632-3.048-4.156-4.34l-.6-.462c-.185-.139-.416-.324-.6-.462l-1.248-.831-1.339-.74a4.658 4.658 0 00-.693-.323l-.692-.323c-1.847-.831-3.787-1.478-5.773-1.847a34.054 34.054 0 00-6.141-.415z"
        ></path>
        <path
          fill="#839DF9"
          d="M121.848 97.141l-.831.277c-12.33 4.295-14.87 22.027-13.53 24.983 5.356 1.57 9.42-1.109 15.238-5.357-4.848-2.032-8.219-10.021-.877-19.903z"
        ></path>
        <g clipPath="url(#clip0)">
          <path
            fill="#283250"
            d="M159.611 142.428H.127c-.071 0-.127-.235-.127-.51 0-.274.056-.49.127-.49h159.484c.071 0 .127.216.127.49.005.275-.056.51-.127.51z"
          ></path>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="57.277"
            x2="86.398"
            y1="14.154"
            y2="14.154"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4A332B"></stop>
            <stop offset="1" stopColor="#382723"></stop>
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="80.012"
            x2="80.13"
            y1="14.741"
            y2="17.984"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#BA916C"></stop>
            <stop offset="1" stopColor="#BA916C" stopOpacity="0"></stop>
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="78.504"
            x2="81.651"
            y1="14.276"
            y2="15.082"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4A332B"></stop>
            <stop offset="1" stopColor="#382723"></stop>
          </linearGradient>
          <clipPath id="clip0">
            <path
              fill="#fff"
              d="M0 0H159.743V1H0z"
              transform="translate(0 141.428)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export const Expired = () => {
  return (
    <svg
      width={240}
      height={185}
      viewBox="0 0 167 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={0.2}
        d="M103.868 165.295s-50.54 29.85-50.54 58.131h101.094c-.007-28.281-50.554-58.131-50.554-58.131z"
        fill="#407BFF"
      />
      <path
        opacity={0.2}
        d="M103.867 200.91s-46.668 11.564-46.668 22.517h93.337c0-10.953-46.669-22.517-46.669-22.517z"
        fill="#407BFF"
      />
      <path
        opacity={0.3}
        d="M110.002 173.536c9.49 9.336 27.935 30.022 27.935 49.89H125.29c0-19.868-10.096-40.554-15.288-49.89z"
        fill="#fff"
      />
      <path
        opacity={0.2}
        d="M103.868 165.294s-50.54-29.85-50.54-58.131h101.094c-.007 28.281-50.554 58.131-50.554 58.131z"
        fill="#407BFF"
      />
      <path
        opacity={0.2}
        d="M103.867 162.571s-25.803-12.383-25.803-24.147h51.613c0 11.743-25.81 24.147-25.81 24.147z"
        fill="#407BFF"
      />
      <path
        opacity={0.3}
        d="M98.36 157.053c-9.484-9.336-27.908-30.022-27.908-49.89h12.647c-.028 19.868 10.047 40.554 15.26 49.89zM110.002 157.053c9.49-9.336 27.474-32.416 27.935-49.89h-3.37c-1.582 21.306-17.055 40.857-24.565 49.89z"
        fill="#fff"
      />
      <path
        d="M147.551 97.182h-5.728v.688h5.728v-.688zM167 106.634h-.688a8.77 8.77 0 00-8.754-8.764h-6.045v-.688h6.045a9.461 9.461 0 019.442 9.452z"
        fill="#407BFF"
      />
      <path
        d="M157.551 99.59H50.184a7.043 7.043 0 00-7.042 7.044v1.06a7.043 7.043 0 007.042 7.044h107.367a7.043 7.043 0 007.042-7.044v-1.06a7.043 7.043 0 00-7.042-7.044zM157.551 215.853H50.184a7.043 7.043 0 00-7.042 7.044v1.06a7.043 7.043 0 007.042 7.044h107.367a7.043 7.043 0 007.042-7.044v-1.06a7.043 7.043 0 00-7.042-7.044z"
        fill="#407BFF"
      />
      <path
        d="M15.242 154.143l-4.663-4.141 7.95-12.211 4.663 4.135-7.95 12.217z"
        fill="#FF8B7B"
      />
      <path
        d="M11.542 147.374l5.928 4.128a.451.451 0 01.144.626l-2.97 4.898a1.145 1.145 0 01-1.575.296c-2.064-1.466-2.827-2.181-5.296-4.176a91.81 91.81 0 01-7.015-6.116c-1.829-1.899.103-3.735 1.073-3.082 2.29 1.555 6.272 3.268 8.576 3.13.4-.031.8.073 1.135.296z"
        fill="#263238"
      />
      <path
        opacity={0.2}
        d="M23.185 141.932l-4.663-4.141-3.824 5.882 4.78 3.969 3.707-5.71z"
        fill="#000"
      />
      <path
        d="M70.624 80.932s-31.291-2.14-35.17 8.468c-8.824 24.119-20.302 49.704-20.302 49.704l9.223 6.68S43.87 115.962 49.82 96.816c12.035 2.924 33.795 5.421 40.802-2.628 5.984-6.88 1.507-13.256 1.507-13.256H70.624z"
        fill="#263238"
      />
      <path
        opacity={0.3}
        d="M43.823 99.679c-.729 2.944-1.836 11.378-2.524 16.971a172.36 172.36 0 004.264-8.847l-1.74-8.124z"
        fill="#000"
      />
      <path
        d="M23.783 148.805l-10.46-8.201 2.139-4.595 11.69 8.936-3.37 3.86zM9.031 146.995a8.414 8.414 0 001.54.791.149.149 0 00.158-.036.15.15 0 00.035-.156c-.089-.234-.873-2.29-1.691-2.49a.57.57 0 00-.53.152.833.833 0 00-.31.777c.14.407.424.75.798.962zm1.328.379c-1.08-.468-1.754-.984-1.83-1.376a.536.536 0 01.22-.502.26.26 0 01.255-.075c.44.11 1.01 1.134 1.355 1.953z"
        fill="#407BFF"
      />
      <path
        d="M10.538 147.766a.115.115 0 00.069 0 .162.162 0 00.075-.01.16.16 0 00.062-.045c.048-.055 1.218-1.493 1.046-2.38a.753.753 0 00-.406-.53.574.574 0 00-.688.062c-.523.488-.502 2.139-.193 2.821a.187.187 0 00.035.082zm.763-2.635a.457.457 0 01.193.289c.103.523-.454 1.424-.819 1.92-.192-.688-.13-1.913.207-2.229.069-.062.165-.124.364 0l.055.02z"
        fill="#407BFF"
      />
      <path
        d="M56.353 161.36l-6.017 1.204-1.114-16.119 6.114.193 1.017 14.722z"
        fill="#FF8B7B"
      />
      <path
        d="M49.518 160.362l6.877-2.896a.462.462 0 01.633.227l2.626 5.283a1.184 1.184 0 01-.612 1.541c-2.407.97-3.603 1.308-6.622 2.587-1.857.784-6.877 3.055-9.56 3.722-2.682.667-3.287-2.023-2.2-2.545 2.572-1.239 6.836-5.016 7.984-7.1.196-.36.502-.647.874-.819z"
        fill="#263238"
      />
      <path
        d="M47.805 162.343a8.458 8.458 0 001.533-.908.164.164 0 00.062-.158.168.168 0 00-.045-.076.158.158 0 00-.078-.041c-.248-.055-2.476-.502-3.102.103a.569.569 0 00-.158.543.847.847 0 00.502.688 1.8 1.8 0 001.286-.151zm1.052-.949c-.997.688-1.816.983-2.221.825a.541.541 0 01-.317-.468.273.273 0 01.07-.268c.336-.323 1.574-.241 2.468-.089z"
        fill="#407BFF"
      />
      <path
        d="M49.304 161.456a.227.227 0 00.062-.049.156.156 0 000-.144c0-.076-.633-1.891-1.5-2.228a.753.753 0 00-.35-.051.75.75 0 00-.337.113.573.573 0 00-.33.646c.137.688 1.588 1.616 2.352 1.719a.136.136 0 00.103-.006zm-1.878-2.125a.459.459 0 01.358 0c.516.199.99 1.182 1.224 1.774-.688-.227-1.74-.935-1.83-1.417 0-.089 0-.206.187-.316l.061-.041z"
        fill="#407BFF"
      />
      <path
        opacity={0.2}
        d="M55.342 146.645l-6.12-.2.584 8.421 6.093-.11-.557-8.111z"
        fill="#000"
      />
      <path
        d="M85.822 80.933s-44.702 4.23-43.093 17.693c2.352 19.421 4.072 53.426 4.072 53.426l11.395.447s4.278-29.898 1.775-49.786c11.574 0 36.717.612 41.139-5.373 5.247-7.1 2.819-16.407 2.819-16.407H85.822z"
        fill="#263238"
      />
      <path
        d="M59.455 153.2l-13.68-.681-.487-5.951 14.999 1.032-.832 5.6zM75.898 41.506a64.367 64.367 0 01-.357 3.474c-.152 1.15-.296 2.27-.475 3.406-.33 2.263-.784 4.513-1.293 6.776a63.737 63.737 0 01-1.918 6.797 36.226 36.226 0 01-3.116 6.88 17.199 17.199 0 01-2.75 3.501c-.283.264-.579.512-.888.743a11.85 11.85 0 01-.853.633c-.55.388-1.121.744-1.712 1.066a33.295 33.295 0 01-6.877 2.8c-1.135.351-2.276.633-3.439.888-1.162.254-2.27.474-3.438.653a55.298 55.298 0 01-6.877.688 2.352 2.352 0 01-.826-4.589l.09-.034a110.491 110.491 0 0011.849-4.513 32.32 32.32 0 005.075-2.875c.365-.262.688-.53 1.018-.798.152-.131.31-.262.44-.4.122-.109.237-.226.344-.35a9.44 9.44 0 001.17-1.796 29.595 29.595 0 002-5.29 70.103 70.103 0 001.438-5.999c.399-2.05.756-4.128 1.093-6.233.337-2.105.592-4.251.826-6.246v-.117a4.726 4.726 0 015.145-4.035 4.724 4.724 0 014.249 4.97h.082z"
        fill="#407BFF"
      />
      <path
        d="M42.132 73.984l-3.44.214 2.752 6.329s5.948-.131 5.825-3.062l-1.073-1.376a5.6 5.6 0 00-4.064-2.105zM34.924 74.72l2.971 5.82h3.542l-2.75-6.33-3.763.51z"
        fill="#FF8B7B"
      />
      <path
        d="M75.403 31.75l6.65-1.375 5.296-.764a9.815 9.815 0 012.29-.103 10.024 10.024 0 018.761 7.326c1.328 4.816 2.524 10.725 3.439 15.96 1.279 7.272 1.939 18.197 2.063 28.13H72.398c-.688-17.989-4.512-29.58-5.811-36.77a10.061 10.061 0 015.563-10.863l3.253-1.54z"
        fill="#407BFF"
      />
      <path
        d="M72.398 35.708c.076 2.958.688 13.504 3.858 19.496.557-5.614 7.221-22.702 7.221-22.702l-11.08 3.206z"
        fill="#F5F5F5"
      />
      <path
        opacity={0.3}
        d="M102.561 57.735c0-.11 0-.213-.041-.33l-.062-.496-.103-.79-.103-.737-.104-.688c0-.193-.061-.392-.089-.578-.069-.44-.137-.867-.213-1.286a230.531 230.531 0 00-.729-3.963v-.11c-.261-1.376-.53-2.703-.819-4.045v-.09a63.91 63.91 0 00-.37-1.712l-.145-.633-.248-1.094-.206-.853-.041-.18a112.71 112.71 0 00-.846-3.28l-4.78-2.518c.55 2.112 1.073 4.437 1.568 6.817-.572.3-1.1.677-1.568 1.121-1.142 1.019-1.1 3.186.041 5.752a63.619 63.619 0 015.158 23.19c.145 3.24.234 6.522.275 9.707h4.814c-.137-7.89-.577-16.394-1.389-23.204z"
        fill="#000"
      />
      <path
        d="M79.577 18.494l-2.703 4.128-3.672 5.607a5.743 5.743 0 011.135 1.658c.687 1.375 1.224 2.366-1.617 4.3-.605.412-.625 1.065-.488 1.788.165.88.688 1.802 3.741 1.259a14.132 14.132 0 007.69-4.266c1.017-1.025.783-1.788-.2-2.848-2.779-2.992-3.61-7.773-3.886-11.626z"
        fill="#FF8B7B"
      />
      <path
        d="M73.085 37.337l1.946 1.224 3.164-1.341a5.11 5.11 0 00-2.751-1.438 5.446 5.446 0 00-2.359 1.555z"
        fill="#263238"
      />
      <path
        d="M75.41 35.783c1.451.598 2.18 1.947 2.84 3.501 0 0 6.953-5.27 7.098-8.743-.372-1.184-2.112-.977-2.112-.977s-2.118 3.673-7.826 6.219zM75.41 35.782a3.528 3.528 0 00-2.201 3.44s-3.095-3.632-.578-6.377a9.137 9.137 0 012.16-.805s-.757 1.514.619 3.742z"
        fill="#fff"
      />
      <path
        d="M75.644 37.991h-.888c-.288.495-.687 6.948-.845 10.381a30.737 30.737 0 001.863 5.84.283.283 0 000 .07c.049.11.097.213.152.316.096.206.192.413.302.605a38.822 38.822 0 011.479-6.28c-.908-3.977-2.063-10.932-2.063-10.932z"
        fill="#263238"
      />
      <path
        opacity={0.2}
        d="M74.11 26.847l2.256-3.997c.041.096.096.213.151.323.198.499.36 1.012.482 1.534.412 1.782-.963 4.313-2.483 5.504-.062-.11-.117-.227-.179-.344A6.88 6.88 0 0073.3 28.27l-.09-.07.901-1.354z"
        fill="#000"
      />
      <path
        d="M63.12 18.26c-.543-2.441 3.343-5.82 3.343-5.82l-4.601-1.602c-2.077 1.493-.729 5.751 1.258 7.423z"
        fill="#263238"
      />
      <path
        d="M78.622 11.202c1.086 6.109 2.063 9.631-.234 13.532a8.054 8.054 0 01-11.384 2.822 8.056 8.056 0 01-2.873-3.235c-2.578-5.036-3.92-14.172 1.328-18.182a8.148 8.148 0 0113.163 5.063z"
        fill="#FF8B7B"
      />
      <path
        d="M78.704 17.38l-2.888-.791v-3.715s-3.652-2.064-3.088-4.644c.28-1.45.673-2.876 1.176-4.265 0 0 5.79-.372 7.565.371-.46.499-.959.959-1.492 1.376 1.388.961 2.579 2.18 3.507 3.591a4.814 4.814 0 00-1.98.957s.756 5.097-2.8 7.12z"
        fill="#263238"
      />
      <path
        d="M72.06.003c2.126 1.334.895 3.783.895 3.783l5.687.117c-.303-1.795-5.233-3.99-6.581-3.9z"
        fill="#263238"
      />
      <path
        d="M74.282 9.483c-.406 1.142-5.013 2.546-9.215 2.683a19.059 19.059 0 01-6.135-1.155 7.013 7.013 0 012.063-1.466c-2.537-.626-5.749-2.855-6.636-4.67a10.505 10.505 0 015.777-.372A6.19 6.19 0 0157.956.23S68.71 4.496 72.852 2.728C77.349.8 80.767 4.413 77.81 7.165c-2.957 2.752-3.528 2.318-3.528 2.318z"
        fill="#263238"
      />
      <path
        d="M81.909 15.625a5.505 5.505 0 01-1.231 3.715c-1.114 1.328-2.847.268-3.246-1.376-.358-1.493.11-3.797 1.575-4.513 1.465-.715 2.826.502 2.902 2.174z"
        fill="#FF8B7B"
      />
      <path
        d="M69.323 16.884c.152.482 0 .956-.275 1.073-.275.117-.687-.186-.846-.688-.158-.502 0-.956.276-1.073.275-.117.687.18.846.688zM63.993 18.811c.152.475 0 .956-.275 1.066-.275.11-.687-.179-.846-.688-.158-.509 0-.956.276-1.066.275-.11.687.207.845.688z"
        fill="#263238"
      />
      <path
        d="M65.816 18.557a16.099 16.099 0 01-.495 4.224 2.414 2.414 0 002.009-.475l-1.514-3.75z"
        fill="#A02724"
      />
      <path
        d="M69.076 24.142A4.368 4.368 0 0071.62 21.9a.152.152 0 00-.195-.196.147.147 0 00-.08.079 4.128 4.128 0 01-2.895 2.229.146.146 0 00-.094.066.144.144 0 00-.016.113.144.144 0 00.172.11c.191-.04.38-.094.564-.159zM70.506 14.064a.296.296 0 00.103-.103.29.29 0 00-.082-.406 2.855 2.855 0 00-2.655-.275.297.297 0 10.255.537 2.236 2.236 0 012.063.233.295.295 0 00.316.014zM62.935 15.915a.268.268 0 000-.145.296.296 0 00-.386-.158 2.819 2.819 0 00-1.733 2.022.296.296 0 00.578.096 2.262 2.262 0 011.376-1.575.295.295 0 00.165-.24z"
        fill="#263238"
      />
      <path
        d="M96.935 33.877a57.57 57.57 0 012.063 2.683c.647.902 1.321 1.796 1.954 2.71 1.286 1.824 2.482 3.716 3.651 5.642a59.78 59.78 0 013.233 6.06c1.06 2.206 1.9 4.51 2.51 6.88.342 1.378.522 2.79.536 4.21-.017 1.42-.22 2.83-.605 4.197a27.828 27.828 0 01-2.971 6.77 38.47 38.47 0 01-1.898 2.916 71.653 71.653 0 01-2.063 2.718 47.097 47.097 0 01-4.67 4.939 2.359 2.359 0 01-3.61-2.945l.062-.103a79.733 79.733 0 005.584-10.842 24.37 24.37 0 001.54-5.262 9.483 9.483 0 00.09-2.195c-.083-.725-.24-1.44-.468-2.133a31.814 31.814 0 00-2.235-5.09c-.915-1.727-1.946-3.44-3.047-5.18-1.1-1.741-2.255-3.44-3.438-5.112l-1.809-2.518c-.605-.819-1.238-1.692-1.808-2.442l-.042-.055a4.723 4.723 0 014.261-7.613 4.72 4.72 0 013.153 1.765h.027zM31.41 84.626l58.51-2.648a1.374 1.374 0 011.466 1.334l-61.31 2.752a1.374 1.374 0 011.334-1.438z"
        fill="#407BFF"
      />
      <path
        opacity={0.6}
        d="M31.41 84.626l58.51-2.648a1.374 1.374 0 011.466 1.334l-61.31 2.752a1.374 1.374 0 011.334-1.438z"
        fill="#fff"
      />
      <path
        d="M22.098 57.474l41.628-1.885a1.3 1.3 0 011.21.874l7.991 25.364a.69.69 0 01-.64.95L30.66 84.66a1.286 1.286 0 01-1.203-.867l-7.991-25.37a.689.689 0 01.632-.95z"
        fill="#407BFF"
      />
      <path
        opacity={0.6}
        d="M22.098 57.474l41.628-1.885a1.3 1.3 0 011.21.874l7.991 25.364a.69.69 0 01-.64.95L30.66 84.66a1.286 1.286 0 01-1.203-.867l-7.991-25.37a.689.689 0 01.632-.95z"
        fill="#fff"
      />
      <path
        opacity={0.5}
        d="M64.95 56.456l7.991 25.364a.688.688 0 01-.64.956L63.74 55.582a1.3 1.3 0 011.21.874zM74.537 82.673l15.384-.688a1.374 1.374 0 011.465 1.335l-18.183.819a1.376 1.376 0 011.334-1.466z"
        fill="#407BFF"
      />
      <path
        d="M93.971 80.368l-3.933.57 4.03 5.608s6.402-1.026 5.66-3.867l-1.376-1.142a5.584 5.584 0 00-4.38-1.17zM85.224 82.618l4.93 4.3 3.914-.372-4.03-5.607-4.814 1.679z"
        fill="#FF8B7B"
      />
    </svg>
  );
};

export const HomeIcon = () => {
  return (
    <svg
      className="main-head-icon"
      viewBox="0 0 274 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M263 23.8C265.3 21.6 265.1 18.2 262.5 16.2C259.9 14.2 254.8 14.9 251.9 16.6C253.7 13 251.9 8.5 247.7 6.2C243.5 3.9 237.2 4.1 233.2 6.5C229.2 9 225.9 14.7 228 18.2C225 15.8 219.8 15.3 216 17C212.2 18.7 210.5 22.4 211.9 25.5C226.9 25.2 248 24.2 263 23.8Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M266.8 100.7C264.2 98.8001 259.5 98.3001 256.5 99.9001C258.7 97.7001 258.2 94.3001 255.5 92.5001C252.8 90.6001 248 90.7001 245.1 92.4001C247.6 90.1001 246.6 86.3001 243.2 84.9001C239.7 83.4001 234.3 84.7001 232.8 87.4001C232.2 84.6001 231.7 81.7001 230 79.1001C228.3 76.5001 225.4 74.2001 221.6 73.4001C215.7 72.2001 219.3 75.2001 216.7 79.2001C214.1 83.2001 213.2 88.5001 214.3 92.9001C213.2 90.9001 209.9 89.6001 206.9 90.1001C203.9 90.6001 201.7 92.8001 202 95.0001C198.7 93.2001 190.9 95.1001 188.9 97.7001C186.9 100.3 188.8 104 192.7 105.1C190.5 104.8 187.1 106.6 186.8 108.2C186.5 109.8 188.4 111.4 190.6 111.5L266.1 111.1C268.3 108.9 269.5 102.6 266.8 100.7Z"
          fill="#65A19F"
        />
        <path
          d="M15.3001 98.5998C17.7001 95.6999 21.7001 95.1999 24.7001 96.9999C25.8001 90.4999 28.9001 84.3998 33.6001 81.5998C38.3001 78.7998 44.6001 79.5998 47.5001 84.9999C47.2001 78.9999 50.5001 73.0999 54.9001 71.2999C59.3001 69.3999 64.4001 71.3998 67.7001 75.6998C70.9001 79.9998 72.8001 86.6998 72.2001 92.6998C76.3001 90.1998 81.5001 91.3999 84.8001 95.4999C88.1001 99.5999 89.2001 104.8 87.3001 110.1H13.0001C11.9001 106.1 12.9001 101.6 15.3001 98.5998Z"
          fill="#65A19F"
        />
        <path
          d="M273.7 109.5C273.7 107 228.3 105 132.9 105C37.4 105 0 107 0 109.5C0 112 37.4 114 132.8 114C228.3 114.1 273.7 112 273.7 109.5Z"
          fill="#46606E"
        />
        <path
          d="M241.5 95.2002V109.3L245.7 109.6L244.5 95.2002H241.5Z"
          fill="#2F3A4F"
        />
        <path
          d="M243 97.3002C255.6 96.8002 244.9 57.8002 239.6 43.7002C232 57.1002 227.2 97.9002 243 97.3002Z"
          fill="#46606E"
        />
        <path
          d="M232.9 83.9004C233.9 91.8004 237 97.6004 243 97.3004C246.2 97.2004 247.9 94.5004 248.6 90.5004C247 90.4004 245.5 90.3004 243.8 90.0004C240.3 88.8004 236 86.9004 232.9 83.9004Z"
          fill="#364954"
        />
        <path
          d="M33.7 95.2002V109.3L37.7999 109.6L36.5999 95.2002H33.7Z"
          fill="#2F3A4F"
        />
        <path
          d="M35.2001 97.3002C47.8001 96.8002 37.1001 57.8002 31.8001 43.7002C24.1001 57.1002 19.3001 97.9002 35.2001 97.3002Z"
          fill="#46606E"
        />
        <path
          d="M25 83.3999C26 91.5999 29 97.4999 35.1 97.2999C38.6 97.1999 40.3 93.9999 40.8 89.1999C34.2 90.4999 29.1 87.8999 25 83.3999Z"
          fill="#364954"
        />
        <path
          d="M81.5001 43.8002C82.9001 42.3002 82.8001 39.8002 81.2001 38.4002C79.6001 37.0002 76.5001 37.5002 74.7001 38.7002C75.8001 36.1002 74.7001 32.9002 72.1001 31.3002C69.5001 29.7002 65.7001 29.8002 63.3001 31.5002C60.8001 33.2002 58.9001 37.3002 60.1001 39.8002C58.3001 38.1002 55.1001 37.8002 52.8001 39.0002C50.5001 40.2002 49.4001 42.8002 50.3001 45.0002C59.5001 44.9002 72.3001 44.1002 81.5001 43.8002Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M51.8001 21.2998C53.2001 19.7998 53.1001 17.2998 51.5001 15.8998C49.9001 14.4998 46.8001 14.9998 45.0001 16.1998C46.1001 13.5998 45.0001 10.3998 42.4001 8.79982C39.8001 7.19982 36.0001 7.29982 33.5001 9.09982C31.0001 10.7998 29.1001 14.8998 30.3001 17.3998C28.5001 15.6998 25.3001 15.3998 23.0001 16.5998C20.7001 17.7998 19.6001 20.3998 20.5001 22.5998C29.7001 22.2998 42.6001 21.5998 51.8001 21.2998Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M129.7 13.7002H205.3L205.6 109.3H133.2L129.7 13.7002Z"
          fill="#38445E"
        />
        <path d="M215.3 4.2998H166.3V109.3H215.3V4.2998Z" fill="#5B8DC2" />
        <path d="M215.3 4.7002H166.3V7.7002H215.3V4.7002Z" fill="#537CAD" />
        <path
          opacity="0.5"
          d="M170.4 53.6001C171 53.6001 183.1 4.6001 183.1 4.6001H212.7L216.8 53.6001H170.4Z"
          fill="#6ECACC"
        />
        <path
          d="M78.3999 4.2998L80.8999 53.6998H150.8L152.1 4.2998H78.3999Z"
          fill="#D7D7D7"
        />
        <path
          d="M215.3 35.4998V33.8998H209.1V4.2998H207.5V33.8998H176V4.2998H174.4V33.8998H166.2L160.7 35.4998H174.4V76.9998H166.1L166.2 78.5998H174.4V109.4H176V78.4998H207.5V109.3H209.1V78.4998H215.2V76.8998H209V35.4998H215.3ZM207.6 76.9998H176.1V35.4998H207.6V76.9998Z"
          fill="#38445E"
        />
        <path
          d="M227.8 109.3H160L160.8 65.4002H51L50.5 53.7002H227.8V109.3Z"
          fill="white"
        />
        <path
          d="M58.3999 65.3999V109.3H160L160.8 65.3999H58.3999Z"
          fill="#D7D7D7"
        />
        <path d="M119.2 85H74.5V109.3H119.2V85Z" fill="#55678F" />
        <path d="M74.5 85H119.1V86.6L74.5 87.1V85Z" fill="#38445E" />
        <path d="M116.4 90H76.3999V91H116.4V90Z" fill="#38445E" />
        <path
          d="M116.4 93.1001H76.3999V94.1001H116.4V93.1001Z"
          fill="#38445E"
        />
        <path
          d="M116.4 96.2002H76.3999V97.2002H116.4V96.2002Z"
          fill="#38445E"
        />
        <path d="M116.4 99.3999H76.3999V100.4H116.4V99.3999Z" fill="#38445E" />
        <path d="M116.4 102.5H76.3999V103.5H116.4V102.5Z" fill="#38445E" />
        <path d="M53.8999 109.3V105H160.1L160 109.3H53.8999Z" fill="#38445E" />
        <path d="M74.1001 4.6H156.1V0.4L74.1001 0V4.6Z" fill="#38445E" />
        <path d="M164.7 4.6H217.6V0.4L164.7 0V4.6Z" fill="#38445E" />
        <path
          d="M143.1 15.1001L86.2 15.4001L88 53.6001L143.3 53.7001L143.1 15.1001Z"
          fill="#5B8DC2"
        />
        <path
          d="M86.2 15.4001L143.1 15.1001V20.6001L86.5 20.7001L86.2 15.4001Z"
          fill="#537CAD"
        />
        <path
          opacity="0.5"
          d="M117.2 15.3002L119.9 53.6002H125.5L124.6 15.2002L117.2 15.3002Z"
          fill="#6ECACC"
        />
        <path
          d="M129 71.7002L130.2 100.1H137.9L138.7 71.7002H129Z"
          fill="#537CAD"
        />
        <path
          d="M131.4 100.1L130.6 73.6001H138.6L137.9 100.1H131.4Z"
          fill="#5B8DC2"
        />
        <path
          d="M144.9 71.7002L146.1 100.1H153.9L154.6 71.7002H144.9Z"
          fill="#537CAD"
        />
        <path
          d="M147.3 100.1L146.5 73.6001H154.6L153.9 100.1H147.3Z"
          fill="#5B8DC2"
        />
        <path d="M222.7 88.8999H208.9V109.3H222.7V88.8999Z" fill="#38445E" />
        <path
          d="M211.1 109.3L211.5 91.8999H222.3V109.3H211.1Z"
          fill="#55678F"
        />
        <path d="M213.2 97.7998H212.3V101.2H213.2V97.7998Z" fill="#38445E" />
        <path
          d="M166.3 109.7V89.2998H199.4L199.5 109.7H166.3Z"
          fill="#537CAD"
        />
        <path
          d="M171.4 109.3H199.5L199.4 93.6001H171.4V109.3Z"
          fill="#5B8DC2"
        />
        <path
          opacity="0.5"
          d="M178.2 107.8L183.9 94.3999H188.1L183.4 107.8H178.2Z"
          fill="#6ECACC"
        />
        <path
          opacity="0.5"
          d="M187 107.8L190.7 94.3999H192.8L189.9 107.8H187Z"
          fill="#6ECACC"
        />
        <path
          d="M199.5 109.3L198.4 50.5H212.7L212 109.3H199.5Z"
          fill="#D7D7D7"
        />
        <path
          d="M87.5 42.7002L87.6 45.0002H143.3V42.7002H87.5Z"
          fill="#38445E"
        />
        <path d="M93.8999 43L94.2999 54H95.9999V43H93.8999Z" fill="#38445E" />
        <path d="M112.7 43L113.1 54H114.8V43H112.7Z" fill="#38445E" />
        <path d="M101.6 43L102 54H103.6V43H101.6Z" fill="#38445E" />
        <path d="M120.4 43L120.8 54H122.5V43H120.4Z" fill="#38445E" />
        <path d="M129.2 43L129.6 54H131.3V43H129.2Z" fill="#38445E" />
        <path d="M136 43L136.4 54H138.1V43H136Z" fill="#38445E" />
        <path
          d="M160.8 65.3999H58.3999V68.2999H160.8V65.3999Z"
          fill="#B5B5B5"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="237.994"
          y1="25.6414"
          x2="237.994"
          y2="4.6596"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="66.2368"
          y1="45.1372"
          x2="66.2368"
          y2="30.2236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="36.4981"
          y1="22.6071"
          x2="36.4981"
          y2="7.69352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="273.7" height="114.1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NotFound = () => {
  return (
    <svg
      width={260}
      height={200}
      viewBox="0 0 339 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M328.038 184.5c5.44 0 9.879-4.36 9.965-9.801.415-27.116 2.599-67.124-48.47-78.185-41.332-8.955-16.822-82.404-128.135-77.66-79.9 3.405-58.678 70.756-120.8 76.36C-.74 98.95-1.102 151.232.488 175.186c.344 5.236 4.697 9.307 9.95 9.307h317.6v.008z"
        fill="#B5DFEA"
      />
      <path
        opacity={0.55}
        d="M289.532 96.507c-41.331-8.955-16.822-82.404-128.134-77.66-19.006.814-32.283 5.245-42.326 11.593a120.06 120.06 0 0110.803-.916c111.304-4.744 86.803 68.706 128.134 77.661 50.529 10.943 48.933 50.216 48.486 77.308h21.543c5.44 0 9.879-4.36 9.965-9.801.415-27.115 2.598-67.116-48.471-78.185z"
        fill="#90CEDD"
      />
      <path
        d="M240.139 15.722L109.452.466c-5.581-.65-10.638 3.35-11.295 8.931L88.403 92.96c-.65 5.581 3.35 10.638 8.932 11.296l130.686 15.256c5.582.65 10.638-3.35 11.296-8.931l9.754-83.563c.657-5.59-3.343-10.646-8.932-11.296z"
        fill="#D7F0F9"
      />
      <path
        d="M248.108 35.285l.963-8.274c.649-5.581-3.351-10.638-8.932-11.296L109.452.46c-5.581-.65-10.638 3.35-11.295 8.931l-.963 8.274 150.914 17.621z"
        fill="#0582C1"
      />
      <path
        d="M118.346 68.271l16.564-29.19a5.367 5.367 0 015.276-2.677 5.35 5.35 0 014.696 5.933l-3.444 29.527.149.016a3.71 3.71 0 013.256 4.11 3.71 3.71 0 01-4.109 3.256l-.149-.016-.626 5.378a4.004 4.004 0 01-7.953-.924l.626-5.378-11.93-1.393a3.708 3.708 0 01-3.256-4.117l.313-2.709a4.5 4.5 0 01.587-1.816zm15.186 2.184l2.137-18.31c.016-.125-.156-.18-.219-.07l-9.785 17.269a.49.49 0 00.368.728l6.959.814a.49.49 0 00.54-.431zM191.45 76.805l16.564-29.19a5.367 5.367 0 015.276-2.677 5.35 5.35 0 014.696 5.933l-3.444 29.527.149.016a3.71 3.71 0 013.256 4.11 3.71 3.71 0 01-4.109 3.256l-.149-.016-.626 5.378a4.004 4.004 0 01-7.953-.924l.626-5.377-11.93-1.394a3.708 3.708 0 01-3.256-4.117l.313-2.709a4.76 4.76 0 01.587-1.816zm15.194 2.184l2.137-18.31c.016-.125-.157-.18-.219-.07l-9.785 17.269a.49.49 0 00.368.728l6.959.814a.49.49 0 00.54-.43zM158.588 51.097c.963-8.25 5.856-12.454 13.817-11.53 7.953.931 11.749 6.153 10.786 14.395l-3.131 26.811c-.963 8.25-5.855 12.454-13.816 11.53-7.953-.931-11.75-6.152-10.787-14.395l3.131-26.81zm4.909 28.275c-.431 3.679 1.025 5.268 3.608 5.573 2.576.298 4.36-.908 4.791-4.595l3.248-27.843c.431-3.68-1.025-5.269-3.608-5.574-2.576-.297-4.36.908-4.791 4.595l-3.248 27.844z"
        fill="#79C9E8"
      />
      <path
        d="M112.607 12.013a2.738 2.738 0 11-5.44-.634 2.738 2.738 0 115.44.634z"
        fill="#fff"
      />
      <path
        d="M125.711 13.549a2.738 2.738 0 11-5.44-.634 2.738 2.738 0 013.037-2.404 2.726 2.726 0 012.403 3.038z"
        fill="#79C9E8"
      />
      <path
        d="M139.3 15.13a2.738 2.738 0 11-5.44-.635 2.738 2.738 0 013.037-2.403c1.503.18 2.583 1.542 2.403 3.038z"
        fill="#FFBF4D"
      />
      <path
        d="M86.728 110.315s-1.77 7.828-1.472 15.828c.266 7.162-.587 54.255-.587 54.255h5.753s8.188-36.885 8.494-39.797c.305-2.912 4.422-32.039 4.422-32.039l-16.61 1.753z"
        fill="#00668E"
      />
      <path
        d="M90.556 108.171s1.323 10.059 3.577 17.213c2.254 7.147 13.315 55.023 13.315 55.023h5.754s-1.057-36.885-1.456-39.797c-.399-2.912-3.311-32.04-3.311-32.04l-17.88-.399z"
        fill="#0582C1"
      />
      <path
        d="M107.91 180.391v4.015h10.951c.619 0 .869-.806.345-1.15-1.605-1.065-4.165-2.537-6.552-2.865h-4.744zM84.966 180.391v4.015h10.952c.618 0 .868-.806.344-1.15-1.605-1.065-4.165-2.537-6.552-2.865h-4.744z"
        fill="#0582C1"
      />
      <path
        d="M104.716 61.367s3.765-5.723-.556-6.278c-3.319-.43-4.689.563-4.689.563s-3.82-.422-5.918 1.394c-1.432 1.244-6.692 10.88 1.816 14.364 8.509 3.483 6.693-5.44 6.693-5.44l2.654-4.603z"
        fill="#00668E"
      />
      <path
        d="M104.16 55.089c-3.319-.43-4.689.563-4.689.563s-3.82-.422-5.918 1.394c-1.432 1.244-6.692 10.88 1.816 14.364 4 1.636 5.715.532 6.419-1.096-8.07 2.27-8.947-7.46-7.616-10.223 1.354-2.81 4.595-1.519 4.595-1.519 1.268-3.194 5.409-2.85 7.115-2.599-.305-.446-.845-.775-1.722-.884z"
        fill="#05556D"
      />
      <path
        d="M99.417 64.154s-1.12-1.12-1.949 0c-.838 1.12-.282 3.624 1.12 3.765 0 0-.415 3.624-3.21 3.484v2.927h6.552V72.1s4.047-1.676 3.906-5.018c-.141-3.35-1.119-5.723-1.119-5.723s-2.231 2.513-5.3 2.795z"
        fill="#F98D3D"
      />
      <path
        d="M100.426 73.04c.65.274.532.68.564 1.291h.939v-2.23s-1.589.5-3.914-.823c0 .008 1.354 1.323 2.411 1.762z"
        fill="#ED701B"
      />
      <path
        d="M101.765 58.814s-3.397-1.354-3.796-.336c-.258.665.399 1.236 1.307 1.816l-.798.133c-.18.031-.243.258-.102.368.219.18.837.297 2.121.344l1.268-2.325z"
        fill="#F98D3D"
      />
      <path
        d="M126.032 67.324c-6.482-4.799-24.173-8.642-24.173-8.642l-1.456 2.653s16.063 6.223 15.006 7.155c-.626.548-8.47 4.14-11.131 5.354a4.284 4.284 0 01-1.824.376l-8.133-.047s-5.871-.697-10.482 4.603C79.714 83.527 72.7 94.526 72.7 94.526l11.82 17.377 1.308 1.918.007-.023-.007.164s.422.047 1.166.118c3.796.352 15.977 1.315 22.419-.055l.18-.039-.305-28.51c10.286-8.14 15.202-14.434 16.955-16.407a1.213 1.213 0 00-.211-1.745zm-39.656 38.881l-1.151-2.521-3.671-8.023 6.09-6.826-1.096 14.974-.172 2.396z"
        fill="#FFBF4D"
      />
      <path
        d="M85.225 103.685l1.15 2.52-.555 7.601-.016.024-1.307-1.918.728-8.227z"
        fill="#EFAA3A"
      />
      <path
        opacity={0.29}
        d="M106.509 84.46c-1.096.798-.814 7.843-1.088 12.25-.454 7.382-.893 13.769-.572 17.887 1.668-.11 3.218-.29 4.533-.572l.18-.039-.305-28.509c10.285-8.14 15.201-14.434 16.955-16.407a1.19 1.19 0 00-.188-1.746c.008 0-8.126 8.87-19.515 17.136z"
        fill="#F98D2B"
      />
      <path
        d="M111.738 140.608c-.289-2.098-1.886-17.848-2.747-26.459l-4.134.454s6.028 49.574 6.427 65.802h1.91c0-.008-1.057-36.885-1.456-39.797z"
        fill="#00668E"
      />
      <path
        d="M85.232 64.97l-1.244-2.232a8.442 8.442 0 00-7.46-12.384 8.442 8.442 0 00-8.439 8.439 8.442 8.442 0 008.439 8.438c2.348 0 4.47-.962 5.996-2.504l2.708.242z"
        fill="#0582C1"
      />
      <path
        d="M72.137 58.026h.329v1.175c0 .36.11.516.36.516.195 0 .336-.102.516-.32v-1.37h.321v1.925h-.266l-.031-.297h-.016c-.18.21-.368.352-.634.352-.407 0-.587-.258-.587-.752v-1.229h.008zM75.04 60.108v.65h-.329v-2.74h.267l.03.219h.017c.172-.149.39-.266.61-.266.493 0 .76.383.76.978 0 .65-.392 1.041-.83 1.041-.18 0-.36-.086-.533-.219l.008.337zm.47-.384c.313 0 .548-.29.548-.767 0-.423-.141-.712-.509-.712-.164 0-.329.094-.517.258v1.01c.18.156.353.211.478.211zM77.256 59.513c.165.133.337.227.564.227.25 0 .383-.133.383-.298 0-.195-.227-.282-.438-.368-.274-.101-.572-.227-.572-.555 0-.313.243-.548.666-.548.243 0 .454.102.595.219l-.157.203c-.133-.101-.266-.172-.438-.172-.243 0-.352.133-.352.274 0 .18.211.25.43.337.274.102.587.219.587.579 0 .313-.25.572-.704.572-.274 0-.532-.118-.72-.267l.156-.203zM79.535 59.497c.125 0 .234.102.234.25 0 .142-.11.243-.234.243a.233.233 0 01-.235-.242c0-.15.11-.25.235-.25zM80.92 59.497c.125 0 .235.102.235.25 0 .142-.11.243-.235.243a.232.232 0 01-.235-.242.23.23 0 01.235-.25z"
        fill="#fff"
      />
      <path
        d="M52.88 166.441s-1.77-1.659-.58-7.937c1.19-6.278 4.212-12.314 2.725-19.124-1.48-6.818-13.097-23.992-13.128-25.135-.039-1.143-3.585 19.984 2.419 28.313 6.004 8.321 6.724 11.21 6.458 15.586-.266 4.375.063 7.906.72 8.923l1.386-.626z"
        fill="#79C9E8"
      />
      <path
        d="M51.775 153.815c-.696 2.802-1.12 9.824-.008 11.507.102.157.251.071.439-.219-.337-1.159-.564-3.194.078-6.591 1.19-6.278 4.212-12.313 2.724-19.124-1.48-6.818-13.096-23.992-13.127-25.135 0 0 1.753 14.317 7.64 24.165 4.227 7.045 2.951 12.595 2.254 15.397z"
        fill="#49B4D6"
      />
      <path
        d="M54.994 166.01s-3.413-8.517 2.371-15.89c5.785-7.374 15.03-16.846 15.21-20.596 0 0 2.38 17.809-5.15 22.85-7.523 5.041-12.8 5.847-11.5 13.605l-.931.031z"
        fill="#79C9E8"
      />
      <path
        d="M63.118 148.797c-8.391 6.105-9.08 13.143-7.773 16.407.102.25.29.407.548.493-1.127-7.483 4.11-8.337 11.538-13.315 7.523-5.041 5.151-22.85 5.151-22.85s-1.08 13.151-9.464 19.265z"
        fill="#49B4D6"
      />
      <path
        d="M49.38 166.199s1.097-4.509.51-9.378c-.58-4.869-5.668-7.609-10.42-12.752-2.441-2.645-3.17-16.634-1.65-17.933 0 0-8.392 10.168-7.093 17.612 1.292 7.445 14.983 12.282 16.25 15.883 1.269 3.601 1.16 6.615 1.16 6.615l1.244-.047z"
        fill="#79C9E8"
      />
      <path
        d="M34.985 130.135c-2.77 7.507.07 16.603 9.284 22.255 6.779 4.157 4.619 12.807 4.619 12.807l1.111-3.413c.102-1.472.11-3.194-.102-4.963-.579-4.869-5.667-7.609-10.419-12.752-2.356-2.552-3.115-15.656-1.808-17.746a46.305 46.305 0 00-2.685 3.812z"
        fill="#49B4D6"
      />
      <path
        d="M37.67 126.323l.149-.188a.966.966 0 00-.15.188z"
        fill="#49B4D6"
      />
      <path
        d="M56.747 165.635s1.965-7.296 6.834-8.282c4.86-.979 10.45-8.172 10.748-9.582 0 .008-.822 13.558-17.582 17.864z"
        fill="#79C9E8"
      />
      <path
        d="M63.3 159.771c-2.983 1.487-4.22 3.491-4.658 5.315 14.92-4.799 15.687-17.308 15.687-17.308-.063.282-.329.791-.76 1.425-1.463 2.568-5.001 7.937-10.27 10.568z"
        fill="#49B4D6"
      />
      <path
        d="M67.276 162.95H41.835l3.021 18.998a2.968 2.968 0 002.928 2.505H61.32a2.96 2.96 0 002.927-2.505l3.03-18.998z"
        fill="#FFBF4D"
      />
      <path
        d="M66.775 166.097l.5-3.147h-25.44l.5 3.147h24.44z"
        fill="#FF9F50"
      />
      <path
        d="M61.107 162.95l-3.022 18.998a2.968 2.968 0 01-2.927 2.505h6.16a2.96 2.96 0 002.928-2.505l3.022-18.998h-6.161z"
        fill="#FF9F50"
      />
      <path
        d="M242.714 129.071h-114.96v23.374h114.96v-23.374z"
        fill="#FFBF4D"
      />
      <path
        opacity={0.29}
        d="M242.714 129.071h-114.96v5.676h114.96v-5.676z"
        fill="#F98D2B"
      />
      <path
        d="M137.75 129.071h10.497l-2.818 5.676h-10.497l2.818-5.676zM161.39 129.071h10.497l-2.818 5.676H158.58l2.81-5.676zM185.03 129.071h10.49l-2.81 5.676h-10.49l2.81-5.676zM208.671 129.071h10.489l-2.818 5.676H205.86l2.811-5.676zM242.722 129.071v.157l-2.739 5.519h-10.482l2.81-5.676h10.411z"
        fill="#F77E2D"
      />
      <path d="M150.165 152.444h-8.102v31.962h8.102v-31.962z" fill="#79C9E8" />
      <path d="M150.173 152.444h-1.84v31.962h1.84v-31.962z" fill="#49B4D6" />
      <path d="M229.039 152.444h-8.102v31.962h8.102v-31.962z" fill="#79C9E8" />
      <path
        d="M229.039 152.444h-2.395v31.962h2.395v-31.962zM150.165 152.421h-8.102v4.023h8.102v-4.023z"
        fill="#49B4D6"
      />
      <path d="M229.039 152.421h-8.102v4.023h8.102v-4.023z" fill="#49B4D6" />
      <path d="M150.165 125.414h-8.102v3.632h8.102v-3.632z" fill="#79C9E8" />
      <path d="M150.173 125.414h-1.84v3.632h1.84v-3.632z" fill="#49B4D6" />
      <path d="M229.039 125.414h-8.102v3.632h8.102v-3.632z" fill="#79C9E8" />
      <path
        d="M229.039 125.414h-2.395v3.632h2.395v-3.632zM150.165 128.593h-8.102v.454h8.102v-.454z"
        fill="#49B4D6"
      />
      <path d="M229.039 128.593h-8.102v.454h8.102v-.454z" fill="#49B4D6" />
      <path
        d="M148.247 129.069l-11.601 23.374h-8.892v-3.225l9.996-20.149h10.497zM161.391 129.069l-11.593 23.374h10.489l11.601-23.374h-10.497zM195.52 129.069l-11.593 23.374h-10.489l11.593-23.374h10.489zM219.16 129.069l-11.593 23.374h-10.489l11.593-23.374h10.489zM232.311 129.069l-11.593 23.374h10.49l11.514-23.217-10.411-.157z"
        fill="#0582C1"
      />
      <path
        d="M148.247 129.069l-2.818 5.676h-10.497l2.818-5.676h10.497zM171.887 129.069l-2.818 5.676H158.58l2.81-5.676h10.497zM195.52 129.069l-2.81 5.676h-10.49l2.81-5.676h10.49zM219.16 129.069l-2.818 5.676H205.86l2.811-5.676h10.489zM242.722 129.226l-2.74 5.519H229.5l2.811-5.676 10.411.157z"
        fill="#00668E"
      />
      <path
        opacity={0.55}
        d="M240.734 139.185H130.956v11.694h109.778v-11.694z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M147.054 127.478a7.225 7.225 0 10-2.32-14.262 7.225 7.225 0 002.32 14.262z"
        fill="#FFBF4D"
      />
      <path
        d="M149.179 120.342a3.27 3.27 0 00-3.272-3.272 3.27 3.27 0 00-3.272 3.272 3.27 3.27 0 003.272 3.272 3.265 3.265 0 003.272-3.272z"
        fill="#0582C1"
      />
      <path
        d="M226.084 127.481a7.225 7.225 0 10-2.321-14.262 7.225 7.225 0 102.321 14.262z"
        fill="#FFBF4D"
      />
      <path
        d="M228.21 120.342a3.27 3.27 0 00-3.272-3.272 3.27 3.27 0 00-3.272 3.272 3.27 3.27 0 003.272 3.272 3.27 3.27 0 003.272-3.272z"
        fill="#0582C1"
      />
      <path
        d="M286.277 181.924h-36.611l5.596-13.573v-.008l2.255-5.48.618-1.503 1.01-2.442.204-.501 2.254-5.479.415-.994 3.992-9.691c.72-1.746 3.194-1.746 3.914 0l3.992 9.691.415.994 2.255 5.479.203.501 1.01 2.442.618 1.503 2.255 5.48v.008l5.605 13.573z"
        fill="#FFBF4D"
      />
      <path
        opacity={0.29}
        d="M252.06 176.109l-2.403 5.816h36.619l-2.396-5.816h-31.82z"
        fill="#F98D2B"
      />
      <path d="M288.664 178.447H246.73v5.926h41.934v-5.926z" fill="#79C9E8" />
      <path d="M288.664 178.447h-9.057v5.926h9.057v-5.926z" fill="#49B4D6" />
      <path
        opacity={0.29}
        d="M284.836 178.448h-5.182l-4.157-10.09v-.008l-2.254-5.48-.618-1.503-1.01-2.442-.204-.501-2.254-5.48-.415-.994-3.358-8.156.626-1.535c.72-1.745 3.194-1.745 3.914 0l3.992 9.691.415.994 2.254 5.48.204.501 1.01 2.442.618 1.503 2.255 5.48v.008l4.164 10.09z"
        fill="#F98D2B"
      />
      <path
        d="M276.578 158.428h-17.229l2.255-5.48h12.728l2.246 5.48zM280.672 168.346v.008h-25.409v-.008l2.246-5.48h20.909l2.254 5.48z"
        fill="#0582C1"
      />
      <path
        d="M276.578 158.428h-5.174l-2.255-5.48h5.182l2.247 5.48zM280.672 168.346v.008h-5.182v-.008l-2.247-5.48h5.174l2.255 5.48z"
        fill="#00668E"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={185.848}
          y1={159.281}
          x2={185.848}
          y2={140.451}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const TimerIcon = () => {
  return (
    <svg
      width={181}
      height={191}
      viewBox="0 0 181 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143.367 89.645c.028 17.498-6.999 34.331-19.616 46.994-12.982 13.058-31.315 21.202-51.635 21.202-39.354 0-71.258-30.53-71.258-68.19-.024-17.304 6.85-33.964 19.22-46.585C33.085 29.77 51.585 21.454 72.116 21.454c39.342.002 71.251 30.531 71.251 68.191z"
        fill="#2B478B"
      />
      <path
        d="M143.368 89.646c.027 17.497-7 34.33-19.617 46.994L20.071 43.06C33.078 29.767 51.578 21.45 72.11 21.45c39.348.006 71.258 30.536 71.258 68.196z"
        fill="#8C9DF4"
      />
      <path
        d="M64.6 33.2l-2.455.658-.506-1.863 3.66-1.117h1.673v13.131h-2.374L64.6 33.2zM69.798 42.205l4.69-3.819c1.87-1.505 2.55-2.347 2.55-3.52 0-1.285-.953-2.03-2.2-2.03-1.246 0-2.1.652-3.144 1.955L70 33.525c1.303-1.751 2.588-2.736 5.021-2.736 2.667 0 4.497 1.565 4.497 3.931 0 2.052-1.144 3.146-3.543 5.047l-2.803 2.235h6.482v2.006h-9.849l-.007-1.803zM67.063 144.62c-1.052-1.006-1.673-2.365-1.673-5.028 0-3.987 1.985-7.098 5.839-7.098 1.77 0 2.977.547 4.165 1.454l-1.287 1.769c-.953-.707-1.77-1.136-2.958-1.136-2.024 0-3.154 1.751-3.27 4.135.715-.671 1.654-1.305 3.251-1.305 2.646 0 4.768 1.491 4.768 4.135 0 2.59-2.2 4.433-5.061 4.433-1.672 0-2.879-.502-3.774-1.359zm6.443-3.018c0-1.303-1.091-2.272-2.783-2.272-1.693 0-2.765 1.044-2.765 2.326 0 1.322 1.11 2.347 2.823 2.347 1.693.003 2.725-1.022 2.725-2.401zM118.428 93.624l1.674-1.49c.973 1.095 2.063 1.715 3.582 1.715 1.342 0 2.354-.783 2.354-2.013 0-1.285-1.226-2.012-3.133-2.012h-1.109l-.39-1.433 3.679-3.744h-5.898v-1.993h9.005v1.714l-3.795 3.725c2.16.298 4.002 1.36 4.002 3.707 0 2.401-1.946 4.115-4.768 4.115-2.361 0-4.055-.949-5.203-2.29zM15.525 94.464l1.305-1.751c1.031.838 1.946 1.23 3.114 1.23 2 0 3.211-1.694 3.287-4.102-.682.782-1.732 1.378-3.193 1.378-2.899 0-4.827-1.62-4.827-4.173 0-2.552 2.064-4.514 5.08-4.514 1.715 0 2.823.465 3.776 1.379 1.012.968 1.655 2.402 1.655 5.01 0 4.191-2.18 7.096-5.838 7.096-1.886.011-3.21-.641-4.359-1.553zm7.63-7.544c0-1.36-1.109-2.402-2.841-2.402-1.694 0-2.706 1.08-2.706 2.462 0 1.383 1.09 2.326 2.784 2.326 1.731-.002 2.763-1.082 2.763-2.386z"
        fill="#EBF3FA"
      />
      <path
        d="M72.246 158.866c-17.92-.022-35.193-6.413-48.468-17.932C10.502 129.414 2.17 113.586.4 96.521c-1.77-17.065 3.145-34.15 13.794-47.944 10.65-13.793 26.272-23.309 43.838-26.703 17.566-3.393 35.823-.423 51.23 8.336 15.407 8.759 26.865 22.681 32.152 39.067 5.287 16.386 4.026 34.067-3.539 49.613-7.565 15.546-20.895 27.849-37.402 34.523a74.87 74.87 0 01-28.228 5.453zm0-136.035c-17.206.023-33.791 6.16-46.537 17.221C12.963 51.114 4.965 66.312 3.266 82.697c-1.7 16.385 3.021 32.791 13.247 46.034 10.225 13.243 25.226 22.38 42.093 25.638 16.866 3.258 34.396.405 49.189-8.006 14.793-8.41 25.795-21.778 30.87-37.512 5.076-15.733 3.865-32.71-3.399-47.636-7.264-14.927-20.063-26.74-35.913-33.148a71.905 71.905 0 00-27.106-5.236z"
        fill="#172A49"
      />
      <path d="M81.253 15.878H62.327v6.087h18.926v-6.087z" fill="#172A49" />
      <path d="M73.311 10.126h-3.044v6.906h3.044v-6.906z" fill="#172A49" />
      <path
        d="M71.79 11.771a6.082 6.082 0 01-3.274-.95 5.7 5.7 0 01-2.17-2.53 5.413 5.413 0 01-.335-3.258 5.567 5.567 0 011.613-2.887A5.979 5.979 0 0170.64.604a6.138 6.138 0 013.404.321 5.844 5.844 0 012.645 2.077c.647.927.993 2.017.993 3.132a5.523 5.523 0 01-1.728 3.985 6.034 6.034 0 01-4.164 1.653zm0-8.848c-.664 0-1.312.188-1.864.541a3.245 3.245 0 00-1.236 1.44 3.082 3.082 0 00-.19 1.856 3.17 3.17 0 00.918 1.643c.469.45 1.067.755 1.717.879.651.124 1.326.06 1.939-.183a3.328 3.328 0 001.505-1.182 3.113 3.113 0 00.566-1.784 3.146 3.146 0 00-.984-2.269 3.437 3.437 0 00-2.371-.941z"
        fill="#172A49"
      />
      <path
        d="M107.005 90.148c.001 1.937-.18 3.87-.541 5.775a31.293 31.293 0 01-4.904 11.727 32.804 32.804 0 01-9.257 9.005 34.346 34.346 0 01-12.172 4.884c-4.356.84-8.843.842-13.2.007a34.344 34.344 0 01-12.178-4.872 32.794 32.794 0 01-9.266-8.996 31.286 31.286 0 01-4.917-11.722c-.81-4.18-.744-8.474.196-12.63 0-.025.011-.049.016-.075 1.776-7.672 6.426-14.456 13.072-19.071.69-.478 1.398-.93 2.126-1.358 5.27-3.102 11.338-4.739 17.525-4.726.858 0 1.702.03 2.533.091 5.96.422 11.691 2.372 16.592 5.644a32.789 32.789 0 019.189 9.174c.514.775.993 1.572 1.438 2.39a30.812 30.812 0 013.748 14.752z"
        fill="#F99746"
      />
      <path
        d="M62.125 75.797c-1.336 2.026.558 5.544-3.453 4.585-4.01-.96-6.46-1.387-6.684.744-.223 2.13 3.002 2.134 3.002 4.377s-1.448 2.979-3.675 1.915-2.87-2.61-5.46-1.65-4.094.32-4.678-1.599c-.09-.3-.23-.584-.416-.84 0-.026.011-.049.015-.075 1.777-7.674 6.43-14.46 13.08-19.075.688-.478 1.396-.931 2.124-1.359 1.045.037 2.288.41 3.358 1.676 2.34 2.773 3.9 1.387 3.342 4.585-.557 3.198.334 3.625 2.228 3.518 1.894-.107 4.123-.108 4.344 1.279.222 1.387-5.79-.107-7.127 1.919zM80.648 93.01s-2.332.715-2.948-.843c-.616-1.558.089-1.893-1.978-2.355-2.067-.462-2.243-1.81-3.56-1.641-1.318.168-.66.673-2.199 1.767-1.538 1.094-2.947.59-2.903 2.608.044 2.02 2.331 2.335 2.793 3.66.462 1.326-.33 1.895.659 2.904.99 1.01 2.836.568 1.978 1.957-.857 1.388-2.175 2.462-1.516 4.608.659 2.146.197 2.272 1.319 3.786 1.122 1.514 3.297 2.02 3.628 1.011.33-1.01-1.518-2.337-.924-3.346.595-1.009 1.583-.316 1.979-1.83.395-1.514 2-1.516 2.132-2.568.132-1.051-1.935-2.65-1.143-3.323s2.374 1.22 3.606-.463c1.232-1.682.395-3.03 1.67-4.628 1.275-1.6-1.714-2.23-2.593-1.304zM107.006 90.146a30.934 30.934 0 01-.542 5.775 31.125 31.125 0 01-5.288 12.298 3.982 3.982 0 01-2.679-.069c-4.177-1.599-1.086-5.917.585-8.07 1.671-2.153-2.59-1.2-5.68-3.198s.332-7.356-2.842-6.476c-3.175.88-7.018 1.998-8.862-.958-1.844-2.956 1.67-3.438.083-5.917-1.588-2.479.919-3.917 4.095-4.158 3.176-.24 2.757-2.398 6.683-2.718 3.927-.32 3.844 4.477 8.189 4.158 4.345-.319 4.145.639 4.145.639.314-.007.624.055.908.182a30.807 30.807 0 011.205 8.512zM103.258 75.396c-3.614 1.682-5.063-2.892-8.147-1.517-3.114 1.387-2.573-1.279-5.019-2.986-2.445-1.707-7.353 1.387-7.13-1.279.223-2.666 3.898-1.915 4.679-4.05.78-2.135 4.345-1.6 4.345-1.6.209-.073.427-.12.649-.136a32.794 32.794 0 019.185 9.177c.517.776.997 1.573 1.438 2.39zM72.82 66.203s1.893-2.615-1.114-3.385c-3.007-.77-2.953.238-5.289 0-2.335-.238-3.259 1.493-1.505 3.385 1.754 1.892 3.176 5.523 4.763 3.588 1.587-1.936 1.28-3.4 3.145-3.588z"
        fill="#ED7F35"
      />
      <path
        d="M72.397 91.825l-.491.008a1.55 1.55 0 01-.582-.088 1.502 1.502 0 01-.5-.298 1.428 1.428 0 01-.34-.46 1.373 1.373 0 01-.129-.55l.229-41.08a.85.85 0 01.277-.599.928.928 0 01.635-.243.93.93 0 01.647.226.853.853 0 01.295.595l1.376 41.137c.001.178-.035.355-.106.52-.07.164-.175.314-.307.44a1.412 1.412 0 01-.46.292 1.46 1.46 0 01-.544.1z"
        fill="#172A49"
      />
      <path
        d="M72.19 91.83l-.052-.035c-.092-.06.23-.665.714-1.34l25.473-35.57c.286-.399.556-.685.61-.653.055.034-.13.386-.416.786L73.17 90.773c-.447.636-.893 1.108-.978 1.056z"
        fill="#233862"
      />
      <path
        d="M69.458 90.62l.174-.44c.302-.766 1.307-1.107 2.227-.757l48.486 18.465c.541.207.833.733.656 1.185-.179.454-.766.664-1.318.473l-49.15-17.065c-.875-.303-1.358-1.14-1.075-1.86z"
        fill="#172A49"
      />
      <path
        d="M64.584 90.265a6.943 6.943 0 011.194-4.002 7.415 7.415 0 013.316-2.693 7.8 7.8 0 014.32-.476 7.614 7.614 0 013.87 1.901 7.092 7.092 0 012.112 3.638 6.88 6.88 0 01-.354 4.148 7.232 7.232 0 01-2.703 3.26 7.723 7.723 0 01-4.14 1.274c-1.987.031-3.905-.694-5.333-2.016-1.428-1.322-2.249-3.133-2.282-5.034z"
        fill="#2B478B"
      />
      <path
        d="M172.492 131.819s.48.809.169 1.68c-.777 2.171-.757 3.009.596 3.978 0 0 1.869.89 1.625-.11a3.476 3.476 0 01.123-1.797c.571-1.577.591-2.347-.279-3.461l-.376-.563-1.858.273z"
        fill="#FFB27D"
      />
      <path
        d="M173.111 133.399s-.411.069-.536.351c-.402.905-1.329 1.689-1.193 1.832.285.299.929-.284 1.316-.856.246-.404.387-.859.413-1.327z"
        fill="#FFB27D"
      />
      <path
        d="M151.879 108.185c-.27-3.294 3.469-5.552 6.422-3.84l.18.108c4.421 2.724 13.959 15.428 14.615 16.702.657 1.273 2.593 11.398 2.593 11.398s-2.394.837-3.513-.032c0 0-3.884-9.603-4.457-10.083-.573-.481-15.531-8.511-15.658-11.522a75.797 75.797 0 00-.182-2.731z"
        fill="#EF7E29"
      />
      <path
        d="M178.571 182.55s1.783 1.555 1.43 2.662c-.354 1.106-1.956 1.844-2.173 3.195-.217 1.352-1.088 2.089-1.87 2.089h-3.73s-.572-.958.814-1.231c1.387-.274 1.916-1.876 1.83-3.35-.086-1.475.13-3.44.13-3.44l2.956-.657.613.732zM156.705 186.574s.32 3.542-.462 3.911l-10.014-.021s0-1.105 1.608-1.351 4.431-1.146 4.913-3.154c.481-2.008 3.955.615 3.955.615z"
        fill="#2A4A7C"
      />
      <path
        d="M148.25 136.538s.529 6.208 1.143 13.678c.845 10.138 1.859 22.592 1.96 24.952.173 4.103.869 10.649.869 10.649s1.651 1.558 4.956.902c0 0 2.944-9.355 3.786-19.254l.039-.488c.783-10.077 2.001-31.294 2.001-31.294l-14.754.855z"
        fill="#8C9DF4"
      />
      <path
        d="M148.25 136.538s.529 6.208 1.143 13.678l11.571 17.249.039-.488c.783-10.077 2.001-31.294 2.001-31.294l-14.754.855z"
        fill="#707FE5"
      />
      <path
        d="M150.663 137.275s9.519 22.487 15.78 33.055c6.26 10.568 8.216 13.517 8.216 13.517s3.586-1.352 3.912-2.274c0 0-2.394-20.401-6.587-25.374-2.368-2.811-4.378-10.86-4.708-13.794-.384-3.42-1.421-9.185-1.421-9.185l-15.192 4.055z"
        fill="#8C9DF4"
      />
      <path
        d="M158.872 103.542s-6.38 3.097-10.115 2.064a8.22 8.22 0 011.603-1.436c.255-.181.453-.427.571-.709.118-.283.152-.591.098-.891a15.31 15.31 0 01-.122-.787 16.495 16.495 0 01-.197-1.894l2.445-1.915 2.32-1.834c-.081 5.032 3.397 7.402 3.397 7.402z"
        fill="#FFB27D"
      />
      <path
        d="M165.202 134.998c-2.573 1.491-5.013 2.613-7.861 3.243-3.366.764-7.503.538-8.887.74a.59.59 0 01-.403-.097c-.108-.053-.714-3.982-1.33-8.94a7.67 7.67 0 01-.068-.603l-.01-.067a183.052 183.052 0 01-.627-6.019 143.027 143.027 0 01-.353-4.634c-.106-1.845-.15-3.541-.106-4.935.02-.637.059-1.21.117-1.708.366-2.897 1.81-4.967 3.169-6.507l1.338-1.219c1.308.08 2.43-.167 3.692-.957 1.377-.867 2.859-1.8 3.145-1.641 2.111 1.105 3.83 3.966 5.058 7.751a41.638 41.638 0 011.59 7.264c.111.812.205 1.642.277 2.475l.039.458c.165 2.267.436 4.392.736 6.292.404 2.561.857 4.704 1.187 6.214.163.769.286 1.368.361 1.788.065.364-.712.896-1.064 1.102z"
        fill="#AEC3FF"
      />
      <path
        d="M165.23 135.006c-2.573 1.492-5.013 2.614-7.862 3.243-3.366.763-7.503.539-8.886.74a.59.59 0 01-.403-.097c-.109-.053-.715-3.982-1.331-8.94-.027-.2-.056-.401-.069-.603l-.008-.067a183.748 183.748 0 01-.628-6.019 145.918 145.918 0 01-.353-4.634c-.106-1.845-.15-3.54-.106-4.935.02-.637.059-1.211.118-1.708.366-2.896 1.689-5.143 3.136-6.61.316-.321 1.37-1.115 1.37-1.115 1.308.08 2.43-.168 3.692-.957 1.377-.869 2.859-1.801 3.145-1.641 2.111 1.105 3.831 3.966 5.059 7.751a41.626 41.626 0 011.589 7.265c.11.812.204 1.641.277 2.474.015.153.026.306.039.458.166 2.268.429 4.392.736 6.292.405 2.561.858 4.704 1.188 6.214.163.769.286 1.368.36 1.789.06.361-.716.893-1.063 1.1z"
        fill="#F99746"
      />
      <path
        d="M155.558 98.06s-1.572 2.873-4.651 3.724a16.275 16.275 0 01-.198-1.894l2.446-1.915 2.403.086z"
        fill="#ED985F"
      />
      <path
        d="M149.575 99.829s6.164.844 6.665-2.781c.502-3.625 1.637-5.958-2.278-6.65-3.915-.692-4.871.596-5.352 1.768-.48 1.172-.624 7.35.965 7.663z"
        fill="#FFB27D"
      />
      <path
        d="M158.325 89.882c.055-.064.183-.115.469-.137a1.198 1.198 0 00-.537-.137c-.069-.38-.295-1.05-.982-1.201.113.06.21.144.284.245a.806.806 0 01.145.339c-.809-.765-2.522-1.3-7.223-.855-6.195.587-4.071 4.35-3.259 4.788.812.437 2.81-.843 4.308-.758 1.498.085 1.827 2.15 2.341 2.424.515.273.303-.225.984-.534.68-.31.769.777.586 1.49-.183.712.779 1.629.779 1.629l.933-1.001c.934-1.002 1.747-5.183 1.294-6.063a6.732 6.732 0 01-.122-.23z"
        fill="#233862"
      />
      <path
        d="M156.369 114.012s-5.197 3.784-10.706 4.613c-.106-1.846-.15-3.542-.106-4.936l10.812.323z"
        fill="#EF7E29"
      />
      <path
        d="M124.454 109.13c-.081-.085-1.01-2.873-2.859-3.031-1.475-.137-4.735.547-3.776 1.043.32.167 2.107 1.219 3.025 2.736.454.754 1.848.986 1.848.986l1.762-1.734z"
        fill="#FFB27D"
      />
      <path
        d="M157.021 106.706a4.005 4.005 0 01-.448 7.236l-.194.078c-4.881 1.872-19.011 2.627-22.698 1.735-1.438-.347-11.218-4.576-11.218-4.576s.673-2.354 2.062-2.724c0 0 8.903 1.156 10.636 1.368 8.96 1.112 16.787-6.206 19.428-4.562.698.435 1.553.941 2.432 1.445z"
        fill="#F99746"
      />
      <path
        d="M120.274 59.98a.697.697 0 01-.503-.334c-3.27-5.512-9.462-12.975-20.39-17.222a.687.687 0 01-.363-.364.646.646 0 01.001-.503c.069-.16.2-.29.366-.361a.733.733 0 01.525-.018c11.301 4.392 17.703 12.106 21.083 17.805a.646.646 0 01-.032.712.697.697 0 01-.298.236.733.733 0 01-.384.049h-.005z"
        fill="#2B478B"
      />
      <path
        d="M122.827 64.303l-.531-8.37-2.152 3.036-3.82-.285 6.503 5.62z"
        fill="#2B478B"
      />
      <path
        d="M24.492 116.925a.694.694 0 01.505.333c3.27 5.512 9.462 12.976 20.388 17.223a.709.709 0 01.403.359.649.649 0 01-.147.751.712.712 0 01-.511.196.716.716 0 01-.274-.06c-11.3-4.393-17.703-12.107-21.083-17.806a.646.646 0 01.032-.712.692.692 0 01.298-.235.73.73 0 01.384-.049h.005z"
        fill="#8C9DF4"
      />
      <path
        d="M21.94 112.602l.53 8.369 2.153-3.035 3.82.285-6.503-5.619z"
        fill="#8C9DF4"
      />
    </svg>
  );
};
