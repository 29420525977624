import React, { Component } from "react";
import "assets/css/common.css";
import { createVisit, searchUnitData } from "api/visit";
import { getCookie } from "helpers";
import { VisitorSuccess } from "assets/icons";
import workflow from "workflow.json";
import SuccessPage from "pages/successPage";
import VisitorField from "./visitorField";
import PageNotFound from "pages/errorPage";

class Visitor extends Component {
  constructor(props) {
    super(props);
    this.workflowData = workflow[`${this.props.about.aliasName}`]["visitor"];
    this.state = {
      loader: true,
      message: "",
      data: {
        phone: "",
        number_plate: "",
        mode_of_entry: "car",
        fin_number: "",
        employer: "",
        purpose_of_visit: "",
        company_id:"",
      },
      fetchdata:{},
      validation: {
        phone: false,
        fin_number: "",
      },
      blockList: [],
      nricPrier: "XXXXX",
      success: false,
      loading: false,
      errorPage: false,
      phoneObj: this.workflowData["fields"]["phone"],
      blockObj: this.workflowData["fields"]["block"],
      nameObj: this.workflowData["fields"]["name"],
      visit_data: {},
    };
  }

  handleChange = (name, value) => {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  handleChangeNric = (value) => {
    const { data, nricPrier } = this.state;
    let { fin_number } = data;

    let fin = fin_number
      ? value.slice(0, 9)
      : `${nricPrier}${value.slice(0, 4)}`;
    this.setState({ data: { ...data, fin_number: fin } });
  };

  handleCustomChange = (name, value, valid) => {
    const { data, validation } = this.state;
    this.setState({
      data: { ...data, [name]: value },
      validation: { ...validation, [name]: valid },
    });
  };



  handleInputChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    this.setState({ data: { ...data, [name]: value }, validationErrors: null });
  };

  componentDidMount = () => {
    const { blockList } = this.props.about;
    
    !blockList.length
      ? searchUnitData("", getCookie("vms_token"))
        .then(({ data }) => {
          let units = data.map((value) => ({
            label: value.company_name,
            value: value.id,
          }));
          this.setState({ blockList: units, loader: false, fetchdata: data});
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loader: true, errorPage: true });
        })
      : this.setState({ blockList: blockList, loader: false });
  };

  requestParams = () => {
    const { data } = this.state;
    return {
      visits: {
        ...data,
        visitor_type: "guest",
        mode_of_entry:
          data.mode_of_entry === "bike" ? "car" : data.mode_of_entry,
      },
    };
  };

  formSubmit = () => {
    if (this.state.validation.phone) {
      return;
    }
    this.setState({ loading: true });
    createVisit(this.requestParams(), getCookie("vms_token"))
      .then(({ data }) => {
        this.setState({ loading: false, success: true, visit_data: data });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      handleChange,
      handleCustomChange,
      handleInputChange,
      formSubmit,
      handleChangeNric,
      workflowData,

    } = this;
    const {
      errorPage,
      loader,
      visit_data,
      success,
      loading,
      ...rest
    } = this.state;

    const visitorData = {
      ...rest,
      handleCustomChange,
      handleInputChange,
      handleChange,
      formSubmit,
      handleChangeNric,
      loading,
      workflowData,
    };
    if (errorPage) {
      return <PageNotFound />;
    }

    if (success) {
      return (
        <SuccessPage
          content={workflowData["success_message"]["content"]}
          head={workflowData["success_message"]["title"]}
          compo={<VisitorSuccess />}
          subHeading={"Visitor Self-Checkin Service"}
          visit_data={visit_data}
          style={{ width: "100%" }}
        />
      );
    }
    if (loader) {
      return <div id="loader" />;
    } else {
      return <VisitorField {...visitorData} />;
    }
  }
}
export default Visitor;
