import React from "react";
import Head from "components/head";
import RightCard from "./rightCard";
import LeftCard from "./leftCard";
import TicketBorder from "components/ticketBorder";
import { Divider } from "components/QRComponent";
import moment from "moment";

const Invited = ({ data, showQRPage }) => {
  const visiting_time = data.visiting_time;
  const validateTime = moment(visiting_time).isBefore(moment().format());
  return (
    <React.Fragment>
      <Head subHeading={"Smart QR Invite"} />
      <div>{validateTime}</div>
      <div className="qr-root">
        <div className="qr-container">
          <div className="qr-innercontainer">
            <LeftCard data={data} showQRPage={showQRPage} />
          </div>
          <Divider />
          <div style={{ display: "flex" }}>
            <RightCard data={data} />
          </div>
          <TicketBorder />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Invited;
