import React, { Component } from "react";
import { fetchContractorDetails } from "api/contractor";
import Invited from "./invited";
import Expired from "pages/expiredPage";
import PageNotFound from "pages/errorPage";
class Contractor extends Component {
  constructor(props) {
    super(props);
    this.id = props.match.params.id;
    this.state = {
      data: {},
      expired: false,
      showQR: false,
      errorPage: false,
      loading: true,
      contractor: "Contractor",
    };
  }

  componentDidMount() {
    this.fetchContractorData();
  }

  fetchContractorData = () => {
    fetchContractorDetails(this.id)
      .then((data) => {
        this.computeStatus(data);
        this.setState({
          showQR: true,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        let expire = "Invite expired..!";
        if (err[0] === 404) {
          this.setState({ showQR: false, errorPage: true });
        } else if (err[1].data[0] === expire) {
          this.setState({ showQR: false, expired: true });
        } else {
          this.setState({ errorPage: true });
        }
      });
  };
  computeStatus = (data) => {
    if (!data?.status) {
      this.setState({ showQR: false, expired: true });
    }
    this.setState({ data: data?.data });
  };
  render() {
    const { expired, data, errorPage, loading, contractor } = this.state;

    const response = { data };
    if (errorPage) {
      return <PageNotFound />;
    }
    if (expired) {
      return <Expired contractor={contractor} />;
    }
    if (loading) {
      return <div id="loader" />;
    } else {
      return <Invited {...response} />;
    }
  }
}
export default Contractor;
